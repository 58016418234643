.g-font-size-10--xs {
  font-size: 0.625rem !important;
}

.g-font-size-11--xs {
  font-size: 0.6875rem !important;
}

.g-font-size-12--xs {
  font-size: 0.75rem !important;
}

.g-font-size-13--xs {
  font-size: 0.8125rem !important;
}

.g-font-size-14--xs {
  font-size: 0.875rem !important;
}

.g-font-size-15--xs {
  font-size: 0.9375rem !important;
}

.g-font-size-16--xs {
  font-size: 1rem !important;
}

.g-font-size-17--xs {
  font-size: 1.0625rem !important;
}

.g-font-size-18--xs {
  font-size: 1.125rem !important;
}

.g-font-size-19--xs {
  font-size: 1.1875rem !important;
}

.g-font-size-20--xs {
  font-size: 1.25rem !important;
}

.g-font-size-22--xs {
  font-size: 1.375rem !important;
}

.g-font-size-24--xs {
  font-size: 1.5rem !important;
}

.g-font-size-26--xs {
  font-size: 1.625rem !important;
}

.g-font-size-28--xs {
  font-size: 1.75rem !important;
}

.g-font-size-30--xs {
  font-size: 1.875rem !important;
}

.g-font-size-32--xs {
  font-size: 2rem !important;
}

.g-font-size-34--xs {
  font-size: 2.125rem !important;
}

.g-font-size-36--xs {
  font-size: 2.25rem !important;
}

.g-font-size-38--xs {
  font-size: 2.375rem !important;
}

.g-font-size-40--xs {
  font-size: 2.5rem !important;
}

.g-font-size-42--xs {
  font-size: 2.625rem !important;
}

.g-font-size-44--xs {
  font-size: 2.75rem !important;
}

.g-font-size-46--xs {
  font-size: 2.875rem !important;
}

.g-font-size-48--xs {
  font-size: 3rem !important;
}

.g-font-size-50--xs {
  font-size: 3.125rem !important;
}

.g-font-size-55--xs {
  font-size: 3.4375rem !important;
}

.g-font-size-60--xs {
  font-size: 3.75rem !important;
}

.g-font-size-65--xs {
  font-size: 4.0625rem !important;
}

.g-font-size-70--xs {
  font-size: 4.375rem !important;
}

.g-font-size-75--xs {
  font-size: 4.6875rem !important;
}

.g-font-size-80--xs {
  font-size: 5rem !important;
}

.g-font-size-85--xs {
  font-size: 5.3125rem !important;
}

.g-font-size-90--xs {
  font-size: 5.625rem !important;
}

.g-font-size-95--xs {
  font-size: 5.9375rem !important;
}

.g-font-size-100--xs {
  font-size: 6.25rem !important;
}

.g-font-size-105--xs {
  font-size: 6.5625rem !important;
}

.g-font-size-110--xs {
  font-size: 6.875rem !important;
}

.g-font-size-115--xs {
  font-size: 7.1875rem !important;
}

.g-font-size-120--xs {
  font-size: 7.5rem !important;
}

.g-font-size-125--xs {
  font-size: 7.8125rem !important;
}

.g-font-size-130--xs {
  font-size: 8.125rem !important;
}

.g-font-size-135--xs {
  font-size: 8.4375rem !important;
}

.g-font-size-140--xs {
  font-size: 8.75rem !important;
}

.g-font-size-145--xs {
  font-size: 9.0625rem !important;
}

.g-font-size-150--xs {
  font-size: 9.375rem !important;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-font-size-10--sm {
    font-size: 0.625rem !important;
  }
  .g-font-size-11--sm {
    font-size: 0.6875rem !important;
  }
  .g-font-size-12--sm {
    font-size: 0.75rem !important;
  }
  .g-font-size-13--sm {
    font-size: 0.8125rem !important;
  }
  .g-font-size-14--sm {
    font-size: 0.875rem !important;
  }
  .g-font-size-15--sm {
    font-size: 0.9375rem !important;
  }
  .g-font-size-16--sm {
    font-size: 1rem !important;
  }
  .g-font-size-17--sm {
    font-size: 1.0625rem !important;
  }
  .g-font-size-18--sm {
    font-size: 1.125rem !important;
  }
  .g-font-size-19--sm {
    font-size: 1.1875rem !important;
  }
  .g-font-size-20--sm {
    font-size: 1.25rem !important;
  }
  .g-font-size-22--sm {
    font-size: 1.375rem !important;
  }
  .g-font-size-24--sm {
    font-size: 1.5rem !important;
  }
  .g-font-size-26--sm {
    font-size: 1.625rem !important;
  }
  .g-font-size-28--sm {
    font-size: 1.75rem !important;
  }
  .g-font-size-30--sm {
    font-size: 1.875rem !important;
  }
  .g-font-size-32--sm {
    font-size: 2rem !important;
  }
  .g-font-size-34--sm {
    font-size: 2.125rem !important;
  }
  .g-font-size-36--sm {
    font-size: 2.25rem !important;
  }
  .g-font-size-38--sm {
    font-size: 2.375rem !important;
  }
  .g-font-size-40--sm {
    font-size: 2.5rem !important;
  }
  .g-font-size-42--sm {
    font-size: 2.625rem !important;
  }
  .g-font-size-44--sm {
    font-size: 2.75rem !important;
  }
  .g-font-size-46--sm {
    font-size: 2.875rem !important;
  }
  .g-font-size-48--sm {
    font-size: 3rem !important;
  }
  .g-font-size-50--sm {
    font-size: 3.125rem !important;
  }
  .g-font-size-55--sm {
    font-size: 3.4375rem !important;
  }
  .g-font-size-60--sm {
    font-size: 3.75rem !important;
  }
  .g-font-size-65--sm {
    font-size: 4.0625rem !important;
  }
  .g-font-size-70--sm {
    font-size: 4.375rem !important;
  }
  .g-font-size-75--sm {
    font-size: 4.6875rem !important;
  }
  .g-font-size-80--sm {
    font-size: 5rem !important;
  }
  .g-font-size-85--sm {
    font-size: 5.3125rem !important;
  }
  .g-font-size-90--sm {
    font-size: 5.625rem !important;
  }
  .g-font-size-95--sm {
    font-size: 5.9375rem !important;
  }
  .g-font-size-100--sm {
    font-size: 6.25rem !important;
  }
  .g-font-size-105--sm {
    font-size: 6.5625rem !important;
  }
  .g-font-size-110--sm {
    font-size: 6.875rem !important;
  }
  .g-font-size-115--sm {
    font-size: 7.1875rem !important;
  }
  .g-font-size-120--sm {
    font-size: 7.5rem !important;
  }
  .g-font-size-125--sm {
    font-size: 7.8125rem !important;
  }
  .g-font-size-130--sm {
    font-size: 8.125rem !important;
  }
  .g-font-size-135--sm {
    font-size: 8.4375rem !important;
  }
  .g-font-size-140--sm {
    font-size: 8.75rem !important;
  }
  .g-font-size-145--sm {
    font-size: 9.0625rem !important;
  }
  .g-font-size-150--sm {
    font-size: 9.375rem !important;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-font-size-10--md {
    font-size: 0.625rem !important;
  }
  .g-font-size-11--md {
    font-size: 0.6875rem !important;
  }
  .g-font-size-12--md {
    font-size: 0.75rem !important;
  }
  .g-font-size-13--md {
    font-size: 0.8125rem !important;
  }
  .g-font-size-14--md {
    font-size: 0.875rem !important;
  }
  .g-font-size-15--md {
    font-size: 0.9375rem !important;
  }
  .g-font-size-16--md {
    font-size: 1rem !important;
  }
  .g-font-size-17--md {
    font-size: 1.0625rem !important;
  }
  .g-font-size-18--md {
    font-size: 1.125rem !important;
  }
  .g-font-size-19--md {
    font-size: 1.1875rem !important;
  }
  .g-font-size-20--md {
    font-size: 1.25rem !important;
  }
  .g-font-size-22--md {
    font-size: 1.375rem !important;
  }
  .g-font-size-24--md {
    font-size: 1.5rem !important;
  }
  .g-font-size-26--md {
    font-size: 1.625rem !important;
  }
  .g-font-size-28--md {
    font-size: 1.75rem !important;
  }
  .g-font-size-30--md {
    font-size: 1.875rem !important;
  }
  .g-font-size-32--md {
    font-size: 2rem !important;
  }
  .g-font-size-34--md {
    font-size: 2.125rem !important;
  }
  .g-font-size-36--md {
    font-size: 2.25rem !important;
  }
  .g-font-size-38--md {
    font-size: 2.375rem !important;
  }
  .g-font-size-40--md {
    font-size: 2.5rem !important;
  }
  .g-font-size-42--md {
    font-size: 2.625rem !important;
  }
  .g-font-size-44--md {
    font-size: 2.75rem !important;
  }
  .g-font-size-46--md {
    font-size: 2.875rem !important;
  }
  .g-font-size-48--md {
    font-size: 3rem !important;
  }
  .g-font-size-50--md {
    font-size: 3.125rem !important;
  }
  .g-font-size-55--md {
    font-size: 3.4375rem !important;
  }
  .g-font-size-60--md {
    font-size: 3.75rem !important;
  }
  .g-font-size-65--md {
    font-size: 4.0625rem !important;
  }
  .g-font-size-70--md {
    font-size: 4.375rem !important;
  }
  .g-font-size-75--md {
    font-size: 4.6875rem !important;
  }
  .g-font-size-80--md {
    font-size: 5rem !important;
  }
  .g-font-size-85--md {
    font-size: 5.3125rem !important;
  }
  .g-font-size-90--md {
    font-size: 5.625rem !important;
  }
  .g-font-size-95--md {
    font-size: 5.9375rem !important;
  }
  .g-font-size-100--md {
    font-size: 6.25rem !important;
  }
  .g-font-size-105--md {
    font-size: 6.5625rem !important;
  }
  .g-font-size-110--md {
    font-size: 6.875rem !important;
  }
  .g-font-size-115--md {
    font-size: 7.1875rem !important;
  }
  .g-font-size-120--md {
    font-size: 7.5rem !important;
  }
  .g-font-size-125--md {
    font-size: 7.8125rem !important;
  }
  .g-font-size-130--md {
    font-size: 8.125rem !important;
  }
  .g-font-size-135--md {
    font-size: 8.4375rem !important;
  }
  .g-font-size-140--md {
    font-size: 8.75rem !important;
  }
  .g-font-size-145--md {
    font-size: 9.0625rem !important;
  }
  .g-font-size-150--md {
    font-size: 9.375rem !important;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-font-size-10--lg {
    font-size: 0.625rem !important;
  }
  .g-font-size-11--lg {
    font-size: 0.6875rem !important;
  }
  .g-font-size-12--lg {
    font-size: 0.75rem !important;
  }
  .g-font-size-13--lg {
    font-size: 0.8125rem !important;
  }
  .g-font-size-14--lg {
    font-size: 0.875rem !important;
  }
  .g-font-size-15--lg {
    font-size: 0.9375rem !important;
  }
  .g-font-size-16--lg {
    font-size: 1rem !important;
  }
  .g-font-size-17--lg {
    font-size: 1.0625rem !important;
  }
  .g-font-size-18--lg {
    font-size: 1.125rem !important;
  }
  .g-font-size-19--lg {
    font-size: 1.1875rem !important;
  }
  .g-font-size-20--lg {
    font-size: 1.25rem !important;
  }
  .g-font-size-22--lg {
    font-size: 1.375rem !important;
  }
  .g-font-size-24--lg {
    font-size: 1.5rem !important;
  }
  .g-font-size-26--lg {
    font-size: 1.625rem !important;
  }
  .g-font-size-28--lg {
    font-size: 1.75rem !important;
  }
  .g-font-size-30--lg {
    font-size: 1.875rem !important;
  }
  .g-font-size-32--lg {
    font-size: 2rem !important;
  }
  .g-font-size-34--lg {
    font-size: 2.125rem !important;
  }
  .g-font-size-36--lg {
    font-size: 2.25rem !important;
  }
  .g-font-size-38--lg {
    font-size: 2.375rem !important;
  }
  .g-font-size-40--lg {
    font-size: 2.5rem !important;
  }
  .g-font-size-42--lg {
    font-size: 2.625rem !important;
  }
  .g-font-size-44--lg {
    font-size: 2.75rem !important;
  }
  .g-font-size-46--lg {
    font-size: 2.875rem !important;
  }
  .g-font-size-48--lg {
    font-size: 3rem !important;
  }
  .g-font-size-50--lg {
    font-size: 3.125rem !important;
  }
  .g-font-size-55--lg {
    font-size: 3.4375rem !important;
  }
  .g-font-size-60--lg {
    font-size: 3.75rem !important;
  }
  .g-font-size-65--lg {
    font-size: 4.0625rem !important;
  }
  .g-font-size-70--lg {
    font-size: 4.375rem !important;
  }
  .g-font-size-75--lg {
    font-size: 4.6875rem !important;
  }
  .g-font-size-80--lg {
    font-size: 5rem !important;
  }
  .g-font-size-85--lg {
    font-size: 5.3125rem !important;
  }
  .g-font-size-90--lg {
    font-size: 5.625rem !important;
  }
  .g-font-size-95--lg {
    font-size: 5.9375rem !important;
  }
  .g-font-size-100--lg {
    font-size: 6.25rem !important;
  }
  .g-font-size-105--lg {
    font-size: 6.5625rem !important;
  }
  .g-font-size-110--lg {
    font-size: 6.875rem !important;
  }
  .g-font-size-115--lg {
    font-size: 7.1875rem !important;
  }
  .g-font-size-120--lg {
    font-size: 7.5rem !important;
  }
  .g-font-size-125--lg {
    font-size: 7.8125rem !important;
  }
  .g-font-size-130--lg {
    font-size: 8.125rem !important;
  }
  .g-font-size-135--lg {
    font-size: 8.4375rem !important;
  }
  .g-font-size-140--lg {
    font-size: 8.75rem !important;
  }
  .g-font-size-145--lg {
    font-size: 9.0625rem !important;
  }
  .g-font-size-150--lg {
    font-size: 9.375rem !important;
  }
}

/*----------------------------------
  Font Family
------------------------------------*/
.g-font-family--primary {
  font-family: Lato, sans-serif !important;
}

.g-font-family--secondary {
  font-family: Montserrat, sans-serif !important;
}

.g-font-family--playfair {
  font-family: Playfair Display, serif !important;
}

/*----------------------------------
  Font Weight
------------------------------------*/
.g-font-weight--300 {
  font-weight: 300 !important;
}

.g-font-weight--400 {
  font-weight: 400 !important;
}

.g-font-weight--500 {
  font-weight: 500 !important;
}

.g-font-weight--600 {
  font-weight: 600 !important;
}

.g-font-weight--700 {
  font-weight: 700 !important;
}

/*----------------------------------
  Letter Spacing
------------------------------------*/
.g-letter-spacing--1 {
  letter-spacing: .0625rem;
}

.g-letter-spacing--2 {
  letter-spacing: .125rem;
}

.g-letter-spacing--3 {
  letter-spacing: .1875rem;
}

/*----------------------------------
  Display
------------------------------------*/
.g-display-none--xs {
  display: none;
}

.g-display-block--xs {
  display: block;
}

.g-display-inline--xs {
  display: inline;
}

.g-display-inline-block--xs {
  display: inline-block;
}

.g-display-inherit--xs {
  display: inherit;
}

.g-display-table--xs {
  display: table;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-display-none--sm {
    display: none;
  }
  .g-display-block--sm {
    display: block;
  }
  .g-display-inline--sm {
    display: inline;
  }
  .g-display-inline-block--sm {
    display: inline-block;
  }
  .g-display-inherit--sm {
    display: inherit;
  }
  .g-display-table--sm {
    display: table;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-display-none--md {
    display: none;
  }
  .g-display-block--md {
    display: block;
  }
  .g-display-inline--md {
    display: inline;
  }
  .g-display-inline-block--md {
    display: inline-block;
  }
  .g-display-inherit--md {
    display: inherit;
  }
  .g-display-table--md {
    display: table;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-display-none--lg {
    display: none;
  }
  .g-display-block--lg {
    display: block;
  }
  .g-display-inline--lg {
    display: inline;
  }
  .g-display-inline-block--lg {
    display: inline-block;
  }
  .g-display-inherit--lg {
    display: inherit;
  }
  .g-display-table--lg {
    display: table;
  }
}

/*----------------------------------
  Text Alignment
------------------------------------*/
.g-text-center--xs {
  text-align: center;
}

.g-text-left--xs {
  text-align: left;
}

.g-text-right--xs {
  text-align: right;
}

.g-text-inherit--xs {
  text-align: inherit;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-text-center--sm {
    text-align: center;
  }
  .g-text-left--sm {
    text-align: left;
  }
  .g-text-right--sm {
    text-align: right;
  }
  .g-text-inherit--sm {
    text-align: inherit;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-text-center--md {
    text-align: center;
  }
  .g-text-left--md {
    text-align: left;
  }
  .g-text-right--md {
    text-align: right;
  }
  .g-text-inherit--md {
    text-align: inherit;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-text-center--lg {
    text-align: center;
  }
  .g-text-left--lg {
    text-align: left;
  }
  .g-text-right--lg {
    text-align: right;
  }
  .g-text-inherit--lg {
    text-align: inherit;
  }
}

/*----------------------------------
  Pulls
------------------------------------*/
.g-pull-left--xs {
  float: left;
}

.g-pull-right--xs {
  float: right;
}

.g-pull-none--xs {
  float: none;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-pull-left--sm {
    float: left;
  }
  .g-pull-right--sm {
    float: right;
  }
  .g-pull-none--sm {
    float: none;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-pull-left--md {
    float: left;
  }
  .g-pull-right--md {
    float: right;
  }
  .g-pull-none--md {
    float: none;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-pull-left--lg {
    float: left;
  }
  .g-pull-right--lg {
    float: right;
  }
  .g-pull-none--lg {
    float: none;
  }
}

/*----------------------------------
  Fullheight
------------------------------------*/
.g-fullheight--xs {
  height: 100vh;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-fullheight--sm {
    height: 100vh;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-fullheight--md {
    height: 100vh;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-fullheight--lg {
    height: 100vh;
  }
}

/*----------------------------------
  Vertical Center Align
------------------------------------*/
.g-ver-center--xs {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0);
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-ver-center--sm {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-ver-center--md {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-ver-center--lg {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}

/*----------------------------------
  Overflow
------------------------------------*/
.g-overflow--hidden {
  overflow: hidden;
}

/*----------------------------------
  Overlay
------------------------------------*/
.g-overlay {
  position: relative;
  z-index: 1;
}

.g-overlay:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  height: 100%;
  content: " ";
}

/*----------------------------------
  Background Position
------------------------------------*/
.g-bg-position--center {
  background-size: cover !important;
  background-position: center center !important;
}

.g-bg--fixed {
  background-attachment: fixed;
  min-height: 100%;
}

/*----------------------------------
  Promo Section
------------------------------------*/
.g-promo-section {
  position: relative;
}

.g-promo-section .g-promo-section__img-left--xs {
  position: absolute;
  top: 0;
  left: 0;
}

.g-promo-section .g-promo-section__img-right--xs {
  position: absolute;
  top: 0;
  right: 0;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-promo-section__img-left--sm {
    position: absolute;
    top: 0;
    left: 0;
  }
  .g-promo-section__img-right--sm {
    position: absolute;
    top: 0;
    right: 0;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-promo-section__img-left--md {
    position: absolute;
    top: 0;
    left: 0;
  }
  .g-promo-section__img-right--md {
    position: absolute;
    top: 0;
    right: 0;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-promo-section__img-left--lg {
    position: absolute;
    top: 0;
    left: 0;
  }
  .g-promo-section__img-right--lg {
    position: absolute;
    top: 0;
    right: 0;
  }
}

/*----------------------------------
  Horizontal Center Align
------------------------------------*/
.g-hor-centered-row--xs {
  width: 100%;
  display: table;
}

.g-hor-centered-row--xs .g-hor-centered-row__col {
  display: table-cell;
  float: none;
  vertical-align: middle;
}

/* Medium screen / tablet */
@media (min-width: 34em) {
  .g-hor-centered-row--sm {
    width: 100%;
    display: table;
  }
  .g-hor-centered-row--sm .g-hor-centered-row__col {
    display: table-cell;
    float: none;
    vertical-align: middle;
  }
}

/* Large screen / desktop */
@media (min-width: 48em) {
  .g-hor-centered-row--md {
    width: 100%;
    display: table;
  }
  .g-hor-centered-row--md .g-hor-centered-row__col {
    display: table-cell;
    float: none;
    vertical-align: middle;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-hor-centered-row--lg {
    width: 100%;
    display: table;
  }
  .g-hor-centered-row--lg .g-hor-centered-row__col {
    display: table-cell;
    float: none;
    vertical-align: middle;
  }
}

/*----------------------------------
  Z-index
------------------------------------*/
.g-z-index--1 {
  z-index: 1;
}

.g-z-index--2 {
  z-index: 2;
}

/*----------------------------------
  Line Height
------------------------------------*/
.g-line-height--normal {
  line-height: normal;
}

.g-line-height--exs {
  line-height: .7;
}

.g-line-height--xs {
  line-height: 1;
}

.g-line-height--sm {
  line-height: 1.2;
}

.g-line-height {
  line-height: 1.4;
}

.g-line-height--lg {
  line-height: 1.6;
}

/*----------------------------------
  Media
------------------------------------*/
.g-media {
  float: left;
}

.g-media__body {
  overflow: hidden;
}

/*----------------------------------
  Box Shadow
------------------------------------*/
.g-position--relative {
  position: relative;
}

.g-position--absolute {
  position: absolute;
}

.g-position--overlay {
  position: relative;
  z-index: 1;
}

/*----------------------------------
  Divider
------------------------------------*/
.g-hor-divider__dashed--sky-light {
  border-bottom: 0.0625rem dashed #d5e2e6;
}

.g-hor-divider__solid--sky-light {
  border-bottom: 0.0625rem solid #f5f8f9;
}

.g-hor-divider__solid--white {
  border-bottom: 0.0625rem solid #fff;
}

.g-hor-divider__solid--heading-light {
  border-bottom: 0.0625rem solid #717171;
}

.g-hor-divider__dashed--white-opacity-lightest {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}

/*----------------------------------
  Border
------------------------------------*/
.g-hor-border-1__solid--primary {
  border: 0.0625rem solid #656f8c;
}

.g-hor-border-4__solid--white {
  border: 0.25rem solid #fff;
}

.g-hor-border-4__solid--grey {
  border: 0.0325rem solid #888;
}

/*----------------------------------
  Box Shadow
------------------------------------*/
.g-box-shadow__primary-v1 {
  box-shadow: 0 0.3125rem 1rem 0 rgba(19, 177, 205, 0.2);
}

.g-box-shadow__blueviolet-v1 {
  box-shadow: 0 0 3.125rem 0 rgba(152, 119, 234, 0.3);
}

.g-box-shadow__dark-lightest-v1 {
  box-shadow: 0 -5rem 2.5rem rgba(34, 35, 36, 0.05);
}

.g-box-shadow__dark-lightest-v2 {
  box-shadow: 0 1.25rem 1.25rem rgba(34, 35, 36, 0.05);
}

.g-box-shadow__dark-lightest-v3 {
  box-shadow: 0 0 2.5rem 0.3125rem rgba(34, 35, 36, 0.05);
}

.g-box-shadow__dark-lightest-v4 {
  box-shadow: 0 0.3125rem 1rem 0 rgba(34, 35, 36, 0.2);
}

/*----------------------------------
  Border Radius
------------------------------------*/
.g-radius--3 {
  border-radius: 0.1875rem !important;
}

.g-radius--4 {
  border-radius: 0.25rem !important;
}

.g-radius--10 {
  border-radius: 0.625rem !important;
}

.g-radius--50 {
  border-radius: 3.125rem !important;
}

.g-radius--circle {
  border-radius: 50% !important;
}

.g-radius--left-4 {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.g-radius--right-4 {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.g-radius--left-50 {
  border-top-left-radius: 3.125rem !important;
  border-bottom-left-radius: 3.125rem !important;
}

.g-radius--right-50 {
  border-top-right-radius: 3.125rem !important;
  border-bottom-right-radius: 3.125rem !important;
}

/*----------------------------------
  Media Queries
------------------------------------*/
@media (max-width: 33.9em) {
  .g-full-width--xs {
    width: 100%;
  }
}

@media (max-width: 33.9em) {
  .g-full-width-offset-0--xs {
    margin-left: 0;
  }
}

/*----------------------------------
  Container
------------------------------------*/
/* Small container */
.g-container--sm {
  padding-right: .9375rem;
  padding-left: .9375rem;
  margin-right: auto;
  margin-left: auto;
}

.g-container--sm:before, .g-container--sm:after {
  content: " ";
  display: table;
}

.g-container--sm:after {
  clear: both;
}

@media (min-width: 48em) {
  .g-container--sm {
    width: 750px;
  }
}

/* Medium container */
.g-container--md {
  padding-right: .9375rem;
  padding-left: .9375rem;
  margin-right: auto;
  margin-left: auto;
}

.g-container--md:before, .g-container--md:after {
  content: " ";
  display: table;
}

.g-container--md:after {
  clear: both;
}

@media (min-width: 48em) {
  .g-container--md {
    width: 750px;
  }
}

@media (min-width: 62em) {
  .g-container--md {
    width: 970px;
  }
}

/*----------------------------------
  Row
------------------------------------*/
/* No Space in a row class */
.g-row-col--0 {
  margin-right: 0;
  margin-left: 0;
}

.g-row-col--0 > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.g-row-col--2 {
  margin-right: -.125rem;
  margin-left: -.125rem;
}

.g-row-col--2 > [class*="col-"] {
  padding-left: .125rem;
  padding-right: .125rem;
}

/* 5px space row class */
.g-row-col--5 {
  margin-right: -.3125rem;
  margin-left: -.3125rem;
}

.g-row-col--5 > [class*="col-"] {
  padding-left: .3125rem;
  padding-right: .3125rem;
}

/* 10px space row class */
.g-row-col--10 {
  margin-right: -.625rem;
  margin-left: -.625rem;
}

.g-row-col--10 > [class*="col-"] {
  padding-left: .625rem;
  padding-right: .625rem;
}

/*----------------------------------
  Width
------------------------------------*/
.g-width-auto--xs {
  width: auto;
}

.g-width-100-percent--xs {
  width: 100%;
}

.g-width-5--xs {
  width: 0.3125rem;
}

.g-width-10--xs {
  width: 0.625rem;
}

.g-width-15--xs {
  width: 0.9375rem;
}

.g-width-20--xs {
  width: 1.25rem;
}

.g-width-25--xs {
  width: 1.5625rem;
}

.g-width-30--xs {
  width: 1.875rem;
}

.g-width-35--xs {
  width: 2.1875rem;
}

.g-width-40--xs {
  width: 2.5rem;
}

.g-width-45--xs {
  width: 2.8125rem;
}

.g-width-50--xs {
  width: 3.125rem;
}

.g-width-60--xs {
  width: 3.75rem;
}

.g-width-70--xs {
  width: 4.375rem;
}

.g-width-80--xs {
  width: 5rem;
}

.g-width-90--xs {
  width: 5.625rem;
}

.g-width-100--xs {
  width: 6.25rem;
}

.g-width-150--xs {
  width: 9.375rem;
}

.g-width-200--xs {
  width: 12.5rem;
}

.g-width-250--xs {
  width: 15.625rem;
}

.g-width-300--xs {
  width: 18.75rem;
}

.g-width-350--xs {
  width: 21.875rem;
}

.g-width-400--xs {
  width: 25rem;
}

.g-width-450--xs {
  width: 28.125rem;
}

.g-width-500--xs {
  width: 31.25rem;
}

.g-width-550--xs {
  width: 34.375rem;
}

.g-width-600--xs {
  width: 37.5rem;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-width-auto--sm {
    width: auto;
  }
  .g-width-100-percent--sm {
    width: 100%;
  }
  .g-width-5--sm {
    width: 0.3125rem;
  }
  .g-width-10--sm {
    width: 0.625rem;
  }
  .g-width-15--sm {
    width: 0.9375rem;
  }
  .g-width-20--sm {
    width: 1.25rem;
  }
  .g-width-25--sm {
    width: 1.5625rem;
  }
  .g-width-30--sm {
    width: 1.875rem;
  }
  .g-width-35--sm {
    width: 2.1875rem;
  }
  .g-width-40--sm {
    width: 2.5rem;
  }
  .g-width-45--sm {
    width: 2.8125rem;
  }
  .g-width-50--sm {
    width: 3.125rem;
  }
  .g-width-60--sm {
    width: 3.75rem;
  }
  .g-width-70--sm {
    width: 4.375rem;
  }
  .g-width-80--sm {
    width: 5rem;
  }
  .g-width-90--sm {
    width: 5.625rem;
  }
  .g-width-100--sm {
    width: 6.25rem;
  }
  .g-width-150--sm {
    width: 9.375rem;
  }
  .g-width-200--sm {
    width: 12.5rem;
  }
  .g-width-250--sm {
    width: 15.625rem;
  }
  .g-width-300--sm {
    width: 18.75rem;
  }
  .g-width-350--sm {
    width: 21.875rem;
  }
  .g-width-400--sm {
    width: 25rem;
  }
  .g-width-450--sm {
    width: 28.125rem;
  }
  .g-width-500--sm {
    width: 31.25rem;
  }
  .g-width-550--sm {
    width: 34.375rem;
  }
  .g-width-600--sm {
    width: 37.5rem;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-width-auto--md {
    width: auto;
  }
  .g-width-100-percent--md {
    width: 100%;
  }
  .g-width-5--md {
    width: 0.3125rem;
  }
  .g-width-10--md {
    width: 0.625rem;
  }
  .g-width-15--md {
    width: 0.9375rem;
  }
  .g-width-20--md {
    width: 1.25rem;
  }
  .g-width-25--md {
    width: 1.5625rem;
  }
  .g-width-30--md {
    width: 1.875rem;
  }
  .g-width-35--md {
    width: 2.1875rem;
  }
  .g-width-40--md {
    width: 2.5rem;
  }
  .g-width-45--md {
    width: 2.8125rem;
  }
  .g-width-50--md {
    width: 3.125rem;
  }
  .g-width-60--md {
    width: 3.75rem;
  }
  .g-width-70--md {
    width: 4.375rem;
  }
  .g-width-80--md {
    width: 5rem;
  }
  .g-width-90--md {
    width: 5.625rem;
  }
  .g-width-100--md {
    width: 6.25rem;
  }
  .g-width-150--md {
    width: 9.375rem;
  }
  .g-width-200--md {
    width: 12.5rem;
  }
  .g-width-250--md {
    width: 15.625rem;
  }
  .g-width-300--md {
    width: 18.75rem;
  }
  .g-width-350--md {
    width: 21.875rem;
  }
  .g-width-400--md {
    width: 25rem;
  }
  .g-width-450--md {
    width: 28.125rem;
  }
  .g-width-500--md {
    width: 31.25rem;
  }
  .g-width-550--md {
    width: 34.375rem;
  }
  .g-width-600--md {
    width: 37.5rem;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-width-auto--lg {
    width: auto;
  }
  .g-width-100-percent--lg {
    width: 100%;
  }
  .g-width-5--lg {
    width: 0.3125rem;
  }
  .g-width-10--lg {
    width: 0.625rem;
  }
  .g-width-15--lg {
    width: 0.9375rem;
  }
  .g-width-20--lg {
    width: 1.25rem;
  }
  .g-width-25--lg {
    width: 1.5625rem;
  }
  .g-width-30--lg {
    width: 1.875rem;
  }
  .g-width-35--lg {
    width: 2.1875rem;
  }
  .g-width-40--lg {
    width: 2.5rem;
  }
  .g-width-45--lg {
    width: 2.8125rem;
  }
  .g-width-50--lg {
    width: 3.125rem;
  }
  .g-width-60--lg {
    width: 3.75rem;
  }
  .g-width-70--lg {
    width: 4.375rem;
  }
  .g-width-80--lg {
    width: 5rem;
  }
  .g-width-90--lg {
    width: 5.625rem;
  }
  .g-width-100--lg {
    width: 6.25rem;
  }
  .g-width-150--lg {
    width: 9.375rem;
  }
  .g-width-200--lg {
    width: 12.5rem;
  }
  .g-width-250--lg {
    width: 15.625rem;
  }
  .g-width-300--lg {
    width: 18.75rem;
  }
  .g-width-350--lg {
    width: 21.875rem;
  }
  .g-width-400--lg {
    width: 25rem;
  }
  .g-width-450--lg {
    width: 28.125rem;
  }
  .g-width-500--lg {
    width: 31.25rem;
  }
  .g-width-550--lg {
    width: 34.375rem;
  }
  .g-width-600--lg {
    width: 37.5rem;
  }
}

/*----------------------------------
  Height
------------------------------------*/
.g-height-auto--xs {
  height: auto;
}

.g-height-100-percent--xs {
  height: 100%;
}

.g-height-5--xs {
  height: 0.3125rem;
}

.g-height-10--xs {
  height: 0.625rem;
}

.g-height-15--xs {
  height: 0.9375rem;
}

.g-height-20--xs {
  height: 1.25rem;
}

.g-height-25--xs {
  height: 1.5625rem;
}

.g-height-30--xs {
  height: 1.875rem;
}

.g-height-35--xs {
  height: 2.1875rem;
}

.g-height-40--xs {
  height: 2.5rem;
}

.g-height-45--xs {
  height: 2.8125rem;
}

.g-height-50--xs {
  height: 3.125rem;
}

.g-height-60--xs {
  height: 3.75rem;
}

.g-height-70--xs {
  height: 4.375rem;
}

.g-height-80--xs {
  height: 5rem;
}

.g-height-90--xs {
  height: 5.625rem;
}

.g-height-100--xs {
  height: 6.25rem;
}

.g-height-150--xs {
  height: 9.375rem;
}

.g-height-200--xs {
  height: 12.5rem;
}

.g-height-250--xs {
  height: 15.625rem;
}

.g-height-300--xs {
  height: 18.75rem;
}

.g-height-350--xs {
  height: 21.875rem;
}

.g-height-400--xs {
  height: 25rem;
}

.g-height-450--xs {
  height: 28.125rem;
}

.g-height-500--xs {
  height: 31.25rem;
}

.g-height-550--xs {
  height: 34.375rem;
}

.g-height-600--xs {
  height: 37.5rem;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-height-auto--sm {
    height: auto;
  }
  .g-height-100-percent--sm {
    height: 100%;
  }
  .g-height-5--sm {
    height: 0.3125rem;
  }
  .g-height-10--sm {
    height: 0.625rem;
  }
  .g-height-15--sm {
    height: 0.9375rem;
  }
  .g-height-20--sm {
    height: 1.25rem;
  }
  .g-height-25--sm {
    height: 1.5625rem;
  }
  .g-height-30--sm {
    height: 1.875rem;
  }
  .g-height-35--sm {
    height: 2.1875rem;
  }
  .g-height-40--sm {
    height: 2.5rem;
  }
  .g-height-45--sm {
    height: 2.8125rem;
  }
  .g-height-50--sm {
    height: 3.125rem;
  }
  .g-height-60--sm {
    height: 3.75rem;
  }
  .g-height-70--sm {
    height: 4.375rem;
  }
  .g-height-80--sm {
    height: 5rem;
  }
  .g-height-90--sm {
    height: 5.625rem;
  }
  .g-height-100--sm {
    height: 6.25rem;
  }
  .g-height-150--sm {
    height: 9.375rem;
  }
  .g-height-200--sm {
    height: 12.5rem;
  }
  .g-height-250--sm {
    height: 15.625rem;
  }
  .g-height-300--sm {
    height: 18.75rem;
  }
  .g-height-350--sm {
    height: 21.875rem;
  }
  .g-height-400--sm {
    height: 25rem;
  }
  .g-height-450--sm {
    height: 28.125rem;
  }
  .g-height-500--sm {
    height: 31.25rem;
  }
  .g-height-550--sm {
    height: 34.375rem;
  }
  .g-height-600--sm {
    height: 37.5rem;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-height-auto--md {
    height: auto;
  }
  .g-height-100-percent--md {
    height: 100%;
  }
  .g-height-5--md {
    height: 0.3125rem;
  }
  .g-height-10--md {
    height: 0.625rem;
  }
  .g-height-15--md {
    height: 0.9375rem;
  }
  .g-height-20--md {
    height: 1.25rem;
  }
  .g-height-25--md {
    height: 1.5625rem;
  }
  .g-height-30--md {
    height: 1.875rem;
  }
  .g-height-35--md {
    height: 2.1875rem;
  }
  .g-height-40--md {
    height: 2.5rem;
  }
  .g-height-45--md {
    height: 2.8125rem;
  }
  .g-height-50--md {
    height: 3.125rem;
  }
  .g-height-60--md {
    height: 3.75rem;
  }
  .g-height-70--md {
    height: 4.375rem;
  }
  .g-height-80--md {
    height: 5rem;
  }
  .g-height-90--md {
    height: 5.625rem;
  }
  .g-height-100--md {
    height: 6.25rem;
  }
  .g-height-150--md {
    height: 9.375rem;
  }
  .g-height-200--md {
    height: 12.5rem;
  }
  .g-height-250--md {
    height: 15.625rem;
  }
  .g-height-300--md {
    height: 18.75rem;
  }
  .g-height-350--md {
    height: 21.875rem;
  }
  .g-height-400--md {
    height: 25rem;
  }
  .g-height-450--md {
    height: 28.125rem;
  }
  .g-height-500--md {
    height: 31.25rem;
  }
  .g-height-550--md {
    height: 34.375rem;
  }
  .g-height-600--md {
    height: 37.5rem;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-height-auto--lg {
    height: auto;
  }
  .g-height-100-percent--lg {
    height: 100%;
  }
  .g-height-5--lg {
    height: 0.3125rem;
  }
  .g-height-10--lg {
    height: 0.625rem;
  }
  .g-height-15--lg {
    height: 0.9375rem;
  }
  .g-height-20--lg {
    height: 1.25rem;
  }
  .g-height-25--lg {
    height: 1.5625rem;
  }
  .g-height-30--lg {
    height: 1.875rem;
  }
  .g-height-35--lg {
    height: 2.1875rem;
  }
  .g-height-40--lg {
    height: 2.5rem;
  }
  .g-height-45--lg {
    height: 2.8125rem;
  }
  .g-height-50--lg {
    height: 3.125rem;
  }
  .g-height-60--lg {
    height: 3.75rem;
  }
  .g-height-70--lg {
    height: 4.375rem;
  }
  .g-height-80--lg {
    height: 5rem;
  }
  .g-height-90--lg {
    height: 5.625rem;
  }
  .g-height-100--lg {
    height: 6.25rem;
  }
  .g-height-150--lg {
    height: 9.375rem;
  }
  .g-height-200--lg {
    height: 12.5rem;
  }
  .g-height-250--lg {
    height: 15.625rem;
  }
  .g-height-300--lg {
    height: 18.75rem;
  }
  .g-height-350--lg {
    height: 21.875rem;
  }
  .g-height-400--lg {
    height: 25rem;
  }
  .g-height-450--lg {
    height: 28.125rem;
  }
  .g-height-500--lg {
    height: 31.25rem;
  }
  .g-height-550--lg {
    height: 34.375rem;
  }
  .g-height-600--lg {
    height: 37.5rem;
  }
}

/*----------------------------------
  Margin Bottom
------------------------------------*/
.g-margin-b-2--xs {
  margin-bottom: .125rem;
}

.g-margin-b-4--xs {
  margin-bottom: .25rem;
}

.g-margin-b-0--xs {
  margin-bottom: 0rem;
}

.g-margin-b-5--xs {
  margin-bottom: 0.3125rem;
}

.g-margin-b-10--xs {
  margin-bottom: 0.625rem;
}

.g-margin-b-15--xs {
  margin-bottom: 0.9375rem;
}

.g-margin-b-20--xs {
  margin-bottom: 1.25rem;
}

.g-margin-b-25--xs {
  margin-bottom: 1.5625rem;
}

.g-margin-b-30--xs {
  margin-bottom: 1.875rem;
}

.g-margin-b-35--xs {
  margin-bottom: 2.1875rem;
}

.g-margin-b-40--xs {
  margin-bottom: 2.5rem;
}

.g-margin-b-45--xs {
  margin-bottom: 2.8125rem;
}

.g-margin-b-50--xs {
  margin-bottom: 3.125rem;
}

.g-margin-b-55--xs {
  margin-bottom: 3.4375rem;
}

.g-margin-b-60--xs {
  margin-bottom: 3.75rem;
}

.g-margin-b-65--xs {
  margin-bottom: 4.0625rem;
}

.g-margin-b-70--xs {
  margin-bottom: 4.375rem;
}

.g-margin-b-75--xs {
  margin-bottom: 4.6875rem;
}

.g-margin-b-80--xs {
  margin-bottom: 5rem;
}

.g-margin-b-85--xs {
  margin-bottom: 5.3125rem;
}

.g-margin-b-90--xs {
  margin-bottom: 5.625rem;
}

.g-margin-b-95--xs {
  margin-bottom: 5.9375rem;
}

.g-margin-b-100--xs {
  margin-bottom: 6.25rem;
}

.g-margin-b-105--xs {
  margin-bottom: 6.5625rem;
}

.g-margin-b-110--xs {
  margin-bottom: 6.875rem;
}

.g-margin-b-115--xs {
  margin-bottom: 7.1875rem;
}

.g-margin-b-120--xs {
  margin-bottom: 7.5rem;
}

.g-margin-b-125--xs {
  margin-bottom: 7.8125rem;
}

.g-margin-b-130--xs {
  margin-bottom: 8.125rem;
}

.g-margin-b-135--xs {
  margin-bottom: 8.4375rem;
}

.g-margin-b-140--xs {
  margin-bottom: 8.75rem;
}

.g-margin-b-145--xs {
  margin-bottom: 9.0625rem;
}

.g-margin-b-150--xs {
  margin-bottom: 9.375rem;
}

.g-margin-b-250--xs {
  margin-bottom: 15.625rem;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-margin-b-2--sm {
    margin-bottom: .125rem;
  }
  .g-margin-b-4--sm {
    margin-bottom: .25rem;
  }
  .g-margin-b-0--sm {
    margin-bottom: 0rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-5--sm {
    margin-bottom: 0.3125rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-10--sm {
    margin-bottom: 0.625rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-15--sm {
    margin-bottom: 0.9375rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-20--sm {
    margin-bottom: 1.25rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-25--sm {
    margin-bottom: 1.5625rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-30--sm {
    margin-bottom: 1.875rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-35--sm {
    margin-bottom: 2.1875rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-40--sm {
    margin-bottom: 2.5rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-45--sm {
    margin-bottom: 2.8125rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-50--sm {
    margin-bottom: 3.125rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-55--sm {
    margin-bottom: 3.4375rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-60--sm {
    margin-bottom: 3.75rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-65--sm {
    margin-bottom: 4.0625rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-70--sm {
    margin-bottom: 4.375rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-75--sm {
    margin-bottom: 4.6875rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-80--sm {
    margin-bottom: 5rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-85--sm {
    margin-bottom: 5.3125rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-90--sm {
    margin-bottom: 5.625rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-95--sm {
    margin-bottom: 5.9375rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-100--sm {
    margin-bottom: 6.25rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-105--sm {
    margin-bottom: 6.5625rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-110--sm {
    margin-bottom: 6.875rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-115--sm {
    margin-bottom: 7.1875rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-120--sm {
    margin-bottom: 7.5rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-125--sm {
    margin-bottom: 7.8125rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-130--sm {
    margin-bottom: 8.125rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-135--sm {
    margin-bottom: 8.4375rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-140--sm {
    margin-bottom: 8.75rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-145--sm {
    margin-bottom: 9.0625rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
  .g-margin-b-150--sm {
    margin-bottom: 9.375rem;
  }
  .g-margin-b-250--sm {
    margin-bottom: 15.625rem;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-margin-b-2--md {
    margin-bottom: .125rem;
  }
  .g-margin-b-4--md {
    margin-bottom: .25rem;
  }
  .g-margin-b-0--md {
    margin-bottom: 0rem;
  }
  .g-margin-b-5--md {
    margin-bottom: 0.3125rem;
  }
  .g-margin-b-10--md {
    margin-bottom: 0.625rem;
  }
  .g-margin-b-15--md {
    margin-bottom: 0.9375rem;
  }
  .g-margin-b-20--md {
    margin-bottom: 1.25rem;
  }
  .g-margin-b-25--md {
    margin-bottom: 1.5625rem;
  }
  .g-margin-b-30--md {
    margin-bottom: 1.875rem;
  }
  .g-margin-b-35--md {
    margin-bottom: 2.1875rem;
  }
  .g-margin-b-40--md {
    margin-bottom: 2.5rem;
  }
  .g-margin-b-45--md {
    margin-bottom: 2.8125rem;
  }
  .g-margin-b-50--md {
    margin-bottom: 3.125rem;
  }
  .g-margin-b-55--md {
    margin-bottom: 3.4375rem;
  }
  .g-margin-b-60--md {
    margin-bottom: 3.75rem;
  }
  .g-margin-b-65--md {
    margin-bottom: 4.0625rem;
  }
  .g-margin-b-70--md {
    margin-bottom: 4.375rem;
  }
  .g-margin-b-75--md {
    margin-bottom: 4.6875rem;
  }
  .g-margin-b-80--md {
    margin-bottom: 5rem;
  }
  .g-margin-b-85--md {
    margin-bottom: 5.3125rem;
  }
  .g-margin-b-90--md {
    margin-bottom: 5.625rem;
  }
  .g-margin-b-95--md {
    margin-bottom: 5.9375rem;
  }
  .g-margin-b-100--md {
    margin-bottom: 6.25rem;
  }
  .g-margin-b-105--md {
    margin-bottom: 6.5625rem;
  }
  .g-margin-b-110--md {
    margin-bottom: 6.875rem;
  }
  .g-margin-b-115--md {
    margin-bottom: 7.1875rem;
  }
  .g-margin-b-120--md {
    margin-bottom: 7.5rem;
  }
  .g-margin-b-125--md {
    margin-bottom: 7.8125rem;
  }
  .g-margin-b-130--md {
    margin-bottom: 8.125rem;
  }
  .g-margin-b-135--md {
    margin-bottom: 8.4375rem;
  }
  .g-margin-b-140--md {
    margin-bottom: 8.75rem;
  }
  .g-margin-b-145--md {
    margin-bottom: 9.0625rem;
  }
  .g-margin-b-150--md {
    margin-bottom: 9.375rem;
  }
  .g-margin-b-250--md {
    margin-bottom: 15.625rem;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-margin-b-2--lg {
    margin-bottom: .125rem;
  }
  .g-margin-b-4--lg {
    margin-bottom: .25rem;
  }
  .g-margin-b-0--lg {
    margin-bottom: 0rem;
  }
  .g-margin-b-5--lg {
    margin-bottom: 0.3125rem;
  }
  .g-margin-b-10--lg {
    margin-bottom: 0.625rem;
  }
  .g-margin-b-15--lg {
    margin-bottom: 0.9375rem;
  }
  .g-margin-b-20--lg {
    margin-bottom: 1.25rem;
  }
  .g-margin-b-25--lg {
    margin-bottom: 1.5625rem;
  }
  .g-margin-b-30--lg {
    margin-bottom: 1.875rem;
  }
  .g-margin-b-35--lg {
    margin-bottom: 2.1875rem;
  }
  .g-margin-b-40--lg {
    margin-bottom: 2.5rem;
  }
  .g-margin-b-45--lg {
    margin-bottom: 2.8125rem;
  }
  .g-margin-b-50--lg {
    margin-bottom: 3.125rem;
  }
  .g-margin-b-55--lg {
    margin-bottom: 3.4375rem;
  }
  .g-margin-b-60--lg {
    margin-bottom: 3.75rem;
  }
  .g-margin-b-65--lg {
    margin-bottom: 4.0625rem;
  }
  .g-margin-b-70--lg {
    margin-bottom: 4.375rem;
  }
  .g-margin-b-75--lg {
    margin-bottom: 4.6875rem;
  }
  .g-margin-b-80--lg {
    margin-bottom: 5rem;
  }
  .g-margin-b-85--lg {
    margin-bottom: 5.3125rem;
  }
  .g-margin-b-90--lg {
    margin-bottom: 5.625rem;
  }
  .g-margin-b-95--lg {
    margin-bottom: 5.9375rem;
  }
  .g-margin-b-100--lg {
    margin-bottom: 6.25rem;
  }
  .g-margin-b-105--lg {
    margin-bottom: 6.5625rem;
  }
  .g-margin-b-110--lg {
    margin-bottom: 6.875rem;
  }
  .g-margin-b-115--lg {
    margin-bottom: 7.1875rem;
  }
  .g-margin-b-120--lg {
    margin-bottom: 7.5rem;
  }
  .g-margin-b-125--lg {
    margin-bottom: 7.8125rem;
  }
  .g-margin-b-130--lg {
    margin-bottom: 8.125rem;
  }
  .g-margin-b-135--lg {
    margin-bottom: 8.4375rem;
  }
  .g-margin-b-140--lg {
    margin-bottom: 8.75rem;
  }
  .g-margin-b-145--lg {
    margin-bottom: 9.0625rem;
  }
  .g-margin-b-150--lg {
    margin-bottom: 9.375rem;
  }
  .g-margin-b-250--lg {
    margin-bottom: 15.625rem;
  }
}

/*----------------------------------
  Margin Top
------------------------------------*/
.g-margin-t-0--xs {
  margin-top: 0rem;
}

.g-margin-t-5--xs {
  margin-top: 0.3125rem;
}

.g-margin-t-10--xs {
  margin-top: 0.625rem;
}

.g-margin-t-15--xs {
  margin-top: 0.9375rem;
}

.g-margin-t-20--xs {
  margin-top: 1.25rem;
}

.g-margin-t-25--xs {
  margin-top: 1.5625rem;
}

.g-margin-t-30--xs {
  margin-top: 1.875rem;
}

.g-margin-t-35--xs {
  margin-top: 2.1875rem;
}

.g-margin-t-40--xs {
  margin-top: 2.5rem;
}

.g-margin-t-45--xs {
  margin-top: 2.8125rem;
}

.g-margin-t-50--xs {
  margin-top: 3.125rem;
}

.g-margin-t-55--xs {
  margin-top: 3.4375rem;
}

.g-margin-t-60--xs {
  margin-top: 3.75rem;
}

.g-margin-t-65--xs {
  margin-top: 4.0625rem;
}

.g-margin-t-70--xs {
  margin-top: 4.375rem;
}

.g-margin-t-75--xs {
  margin-top: 4.6875rem;
}

.g-margin-t-80--xs {
  margin-top: 5rem;
}

.g-margin-t-85--xs {
  margin-top: 5.3125rem;
}

.g-margin-t-90--xs {
  margin-top: 5.625rem;
}

.g-margin-t-95--xs {
  margin-top: 5.9375rem;
}

.g-margin-t-100--xs {
  margin-top: 6.25rem;
}

.g-margin-t-105--xs {
  margin-top: 6.5625rem;
}

.g-margin-t-110--xs {
  margin-top: 6.875rem;
}

.g-margin-t-115--xs {
  margin-top: 7.1875rem;
}

.g-margin-t-120--xs {
  margin-top: 7.5rem;
}

.g-margin-t-125--xs {
  margin-top: 7.8125rem;
}

.g-margin-t-130--xs {
  margin-top: 8.125rem;
}

.g-margin-t-135--xs {
  margin-top: 8.4375rem;
}

.g-margin-t-140--xs {
  margin-top: 8.75rem;
}

.g-margin-t-145--xs {
  margin-top: 9.0625rem;
}

.g-margin-t-150--xs {
  margin-top: 9.375rem;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-margin-t-0--sm {
    margin-top: 0rem;
  }
  .g-margin-t-5--sm {
    margin-top: 0.3125rem;
  }
  .g-margin-t-10--sm {
    margin-top: 0.625rem;
  }
  .g-margin-t-15--sm {
    margin-top: 0.9375rem;
  }
  .g-margin-t-20--sm {
    margin-top: 1.25rem;
  }
  .g-margin-t-25--sm {
    margin-top: 1.5625rem;
  }
  .g-margin-t-30--sm {
    margin-top: 1.875rem;
  }
  .g-margin-t-35--sm {
    margin-top: 2.1875rem;
  }
  .g-margin-t-40--sm {
    margin-top: 2.5rem;
  }
  .g-margin-t-45--sm {
    margin-top: 2.8125rem;
  }
  .g-margin-t-50--sm {
    margin-top: 3.125rem;
  }
  .g-margin-t-55--sm {
    margin-top: 3.4375rem;
  }
  .g-margin-t-60--sm {
    margin-top: 3.75rem;
  }
  .g-margin-t-65--sm {
    margin-top: 4.0625rem;
  }
  .g-margin-t-70--sm {
    margin-top: 4.375rem;
  }
  .g-margin-t-75--sm {
    margin-top: 4.6875rem;
  }
  .g-margin-t-80--sm {
    margin-top: 5rem;
  }
  .g-margin-t-85--sm {
    margin-top: 5.3125rem;
  }
  .g-margin-t-90--sm {
    margin-top: 5.625rem;
  }
  .g-margin-t-95--sm {
    margin-top: 5.9375rem;
  }
  .g-margin-t-100--sm {
    margin-top: 6.25rem;
  }
  .g-margin-t-105--sm {
    margin-top: 6.5625rem;
  }
  .g-margin-t-110--sm {
    margin-top: 6.875rem;
  }
  .g-margin-t-115--sm {
    margin-top: 7.1875rem;
  }
  .g-margin-t-120--sm {
    margin-top: 7.5rem;
  }
  .g-margin-t-125--sm {
    margin-top: 7.8125rem;
  }
  .g-margin-t-130--sm {
    margin-top: 8.125rem;
  }
  .g-margin-t-135--sm {
    margin-top: 8.4375rem;
  }
  .g-margin-t-140--sm {
    margin-top: 8.75rem;
  }
  .g-margin-t-145--sm {
    margin-top: 9.0625rem;
  }
  .g-margin-t-150--sm {
    margin-top: 9.375rem;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-margin-t-0--md {
    margin-top: 0rem;
  }
  .g-margin-t-5--md {
    margin-top: 0.3125rem;
  }
  .g-margin-t-10--md {
    margin-top: 0.625rem;
  }
  .g-margin-t-15--md {
    margin-top: 0.9375rem;
  }
  .g-margin-t-20--md {
    margin-top: 1.25rem;
  }
  .g-margin-t-25--md {
    margin-top: 1.5625rem;
  }
  .g-margin-t-30--md {
    margin-top: 1.875rem;
  }
  .g-margin-t-35--md {
    margin-top: 2.1875rem;
  }
  .g-margin-t-40--md {
    margin-top: 2.5rem;
  }
  .g-margin-t-45--md {
    margin-top: 2.8125rem;
  }
  .g-margin-t-50--md {
    margin-top: 3.125rem;
  }
  .g-margin-t-55--md {
    margin-top: 3.4375rem;
  }
  .g-margin-t-60--md {
    margin-top: 3.75rem;
  }
  .g-margin-t-65--md {
    margin-top: 4.0625rem;
  }
  .g-margin-t-70--md {
    margin-top: 4.375rem;
  }
  .g-margin-t-75--md {
    margin-top: 4.6875rem;
  }
  .g-margin-t-80--md {
    margin-top: 5rem;
  }
  .g-margin-t-85--md {
    margin-top: 5.3125rem;
  }
  .g-margin-t-90--md {
    margin-top: 5.625rem;
  }
  .g-margin-t-95--md {
    margin-top: 5.9375rem;
  }
  .g-margin-t-100--md {
    margin-top: 6.25rem;
  }
  .g-margin-t-105--md {
    margin-top: 6.5625rem;
  }
  .g-margin-t-110--md {
    margin-top: 6.875rem;
  }
  .g-margin-t-115--md {
    margin-top: 7.1875rem;
  }
  .g-margin-t-120--md {
    margin-top: 7.5rem;
  }
  .g-margin-t-125--md {
    margin-top: 7.8125rem;
  }
  .g-margin-t-130--md {
    margin-top: 8.125rem;
  }
  .g-margin-t-135--md {
    margin-top: 8.4375rem;
  }
  .g-margin-t-140--md {
    margin-top: 8.75rem;
  }
  .g-margin-t-145--md {
    margin-top: 9.0625rem;
  }
  .g-margin-t-150--md {
    margin-top: 9.375rem;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-margin-t-0--lg {
    margin-top: 0rem;
  }
  .g-margin-t-5--lg {
    margin-top: 0.3125rem;
  }
  .g-margin-t-10--lg {
    margin-top: 0.625rem;
  }
  .g-margin-t-15--lg {
    margin-top: 0.9375rem;
  }
  .g-margin-t-20--lg {
    margin-top: 1.25rem;
  }
  .g-margin-t-25--lg {
    margin-top: 1.5625rem;
  }
  .g-margin-t-30--lg {
    margin-top: 1.875rem;
  }
  .g-margin-t-35--lg {
    margin-top: 2.1875rem;
  }
  .g-margin-t-40--lg {
    margin-top: 2.5rem;
  }
  .g-margin-t-45--lg {
    margin-top: 2.8125rem;
  }
  .g-margin-t-50--lg {
    margin-top: 3.125rem;
  }
  .g-margin-t-55--lg {
    margin-top: 3.4375rem;
  }
  .g-margin-t-60--lg {
    margin-top: 3.75rem;
  }
  .g-margin-t-65--lg {
    margin-top: 4.0625rem;
  }
  .g-margin-t-70--lg {
    margin-top: 4.375rem;
  }
  .g-margin-t-75--lg {
    margin-top: 4.6875rem;
  }
  .g-margin-t-80--lg {
    margin-top: 5rem;
  }
  .g-margin-t-85--lg {
    margin-top: 5.3125rem;
  }
  .g-margin-t-90--lg {
    margin-top: 5.625rem;
  }
  .g-margin-t-95--lg {
    margin-top: 5.9375rem;
  }
  .g-margin-t-100--lg {
    margin-top: 6.25rem;
  }
  .g-margin-t-105--lg {
    margin-top: 6.5625rem;
  }
  .g-margin-t-110--lg {
    margin-top: 6.875rem;
  }
  .g-margin-t-115--lg {
    margin-top: 7.1875rem;
  }
  .g-margin-t-120--lg {
    margin-top: 7.5rem;
  }
  .g-margin-t-125--lg {
    margin-top: 7.8125rem;
  }
  .g-margin-t-130--lg {
    margin-top: 8.125rem;
  }
  .g-margin-t-135--lg {
    margin-top: 8.4375rem;
  }
  .g-margin-t-140--lg {
    margin-top: 8.75rem;
  }
  .g-margin-t-145--lg {
    margin-top: 9.0625rem;
  }
  .g-margin-t-150--lg {
    margin-top: 9.375rem;
  }
}

/*----------------------------------
  Margin Left
------------------------------------*/
.g-margin-l-0--xs {
  margin-left: 0rem;
}

.g-margin-l-5--xs {
  margin-left: 0.3125rem;
}

.g-margin-l-10--xs {
  margin-left: 0.625rem;
}

.g-margin-l-15--xs {
  margin-left: 0.9375rem;
}

.g-margin-l-20--xs {
  margin-left: 1.25rem;
}

.g-margin-l-25--xs {
  margin-left: 1.5625rem;
}

.g-margin-l-30--xs {
  margin-left: 1.875rem;
}

.g-margin-l-35--xs {
  margin-left: 2.1875rem;
}

.g-margin-l-40--xs {
  margin-left: 2.5rem;
}

.g-margin-l-45--xs {
  margin-left: 2.8125rem;
}

.g-margin-l-50--xs {
  margin-left: 3.125rem;
}

.g-margin-l-55--xs {
  margin-left: 3.4375rem;
}

.g-margin-l-60--xs {
  margin-left: 3.75rem;
}

.g-margin-l-65--xs {
  margin-left: 4.0625rem;
}

.g-margin-l-70--xs {
  margin-left: 4.375rem;
}

.g-margin-l-75--xs {
  margin-left: 4.6875rem;
}

.g-margin-l-80--xs {
  margin-left: 5rem;
}

.g-margin-l-85--xs {
  margin-left: 5.3125rem;
}

.g-margin-l-90--xs {
  margin-left: 5.625rem;
}

.g-margin-l-95--xs {
  margin-left: 5.9375rem;
}

.g-margin-l-100--xs {
  margin-left: 6.25rem;
}

.g-margin-l-105--xs {
  margin-left: 6.5625rem;
}

.g-margin-l-110--xs {
  margin-left: 6.875rem;
}

.g-margin-l-115--xs {
  margin-left: 7.1875rem;
}

.g-margin-l-120--xs {
  margin-left: 7.5rem;
}

.g-margin-l-125--xs {
  margin-left: 7.8125rem;
}

.g-margin-l-130--xs {
  margin-left: 8.125rem;
}

.g-margin-l-135--xs {
  margin-left: 8.4375rem;
}

.g-margin-l-140--xs {
  margin-left: 8.75rem;
}

.g-margin-l-145--xs {
  margin-left: 9.0625rem;
}

.g-margin-l-150--xs {
  margin-left: 9.375rem;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-margin-l-0--sm {
    margin-left: 0rem;
  }
  .g-margin-l-5--sm {
    margin-left: 0.3125rem;
  }
  .g-margin-l-10--sm {
    margin-left: 0.625rem;
  }
  .g-margin-l-15--sm {
    margin-left: 0.9375rem;
  }
  .g-margin-l-20--sm {
    margin-left: 1.25rem;
  }
  .g-margin-l-25--sm {
    margin-left: 1.5625rem;
  }
  .g-margin-l-30--sm {
    margin-left: 1.875rem;
  }
  .g-margin-l-35--sm {
    margin-left: 2.1875rem;
  }
  .g-margin-l-40--sm {
    margin-left: 2.5rem;
  }
  .g-margin-l-45--sm {
    margin-left: 2.8125rem;
  }
  .g-margin-l-50--sm {
    margin-left: 3.125rem;
  }
  .g-margin-l-55--sm {
    margin-left: 3.4375rem;
  }
  .g-margin-l-60--sm {
    margin-left: 3.75rem;
  }
  .g-margin-l-65--sm {
    margin-left: 4.0625rem;
  }
  .g-margin-l-70--sm {
    margin-left: 4.375rem;
  }
  .g-margin-l-75--sm {
    margin-left: 4.6875rem;
  }
  .g-margin-l-80--sm {
    margin-left: 5rem;
  }
  .g-margin-l-85--sm {
    margin-left: 5.3125rem;
  }
  .g-margin-l-90--sm {
    margin-left: 5.625rem;
  }
  .g-margin-l-95--sm {
    margin-left: 5.9375rem;
  }
  .g-margin-l-100--sm {
    margin-left: 6.25rem;
  }
  .g-margin-l-105--sm {
    margin-left: 6.5625rem;
  }
  .g-margin-l-110--sm {
    margin-left: 6.875rem;
  }
  .g-margin-l-115--sm {
    margin-left: 7.1875rem;
  }
  .g-margin-l-120--sm {
    margin-left: 7.5rem;
  }
  .g-margin-l-125--sm {
    margin-left: 7.8125rem;
  }
  .g-margin-l-130--sm {
    margin-left: 8.125rem;
  }
  .g-margin-l-135--sm {
    margin-left: 8.4375rem;
  }
  .g-margin-l-140--sm {
    margin-left: 8.75rem;
  }
  .g-margin-l-145--sm {
    margin-left: 9.0625rem;
  }
  .g-margin-l-150--sm {
    margin-left: 9.375rem;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-margin-l-0--md {
    margin-left: 0rem;
  }
  .g-margin-l-5--md {
    margin-left: 0.3125rem;
  }
  .g-margin-l-10--md {
    margin-left: 0.625rem;
  }
  .g-margin-l-15--md {
    margin-left: 0.9375rem;
  }
  .g-margin-l-20--md {
    margin-left: 1.25rem;
  }
  .g-margin-l-25--md {
    margin-left: 1.5625rem;
  }
  .g-margin-l-30--md {
    margin-left: 1.875rem;
  }
  .g-margin-l-35--md {
    margin-left: 2.1875rem;
  }
  .g-margin-l-40--md {
    margin-left: 2.5rem;
  }
  .g-margin-l-45--md {
    margin-left: 2.8125rem;
  }
  .g-margin-l-50--md {
    margin-left: 3.125rem;
  }
  .g-margin-l-55--md {
    margin-left: 3.4375rem;
  }
  .g-margin-l-60--md {
    margin-left: 3.75rem;
  }
  .g-margin-l-65--md {
    margin-left: 4.0625rem;
  }
  .g-margin-l-70--md {
    margin-left: 4.375rem;
  }
  .g-margin-l-75--md {
    margin-left: 4.6875rem;
  }
  .g-margin-l-80--md {
    margin-left: 5rem;
  }
  .g-margin-l-85--md {
    margin-left: 5.3125rem;
  }
  .g-margin-l-90--md {
    margin-left: 5.625rem;
  }
  .g-margin-l-95--md {
    margin-left: 5.9375rem;
  }
  .g-margin-l-100--md {
    margin-left: 6.25rem;
  }
  .g-margin-l-105--md {
    margin-left: 6.5625rem;
  }
  .g-margin-l-110--md {
    margin-left: 6.875rem;
  }
  .g-margin-l-115--md {
    margin-left: 7.1875rem;
  }
  .g-margin-l-120--md {
    margin-left: 7.5rem;
  }
  .g-margin-l-125--md {
    margin-left: 7.8125rem;
  }
  .g-margin-l-130--md {
    margin-left: 8.125rem;
  }
  .g-margin-l-135--md {
    margin-left: 8.4375rem;
  }
  .g-margin-l-140--md {
    margin-left: 8.75rem;
  }
  .g-margin-l-145--md {
    margin-left: 9.0625rem;
  }
  .g-margin-l-150--md {
    margin-left: 9.375rem;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-margin-l-0--lg {
    margin-left: 0rem;
  }
  .g-margin-l-5--lg {
    margin-left: 0.3125rem;
  }
  .g-margin-l-10--lg {
    margin-left: 0.625rem;
  }
  .g-margin-l-15--lg {
    margin-left: 0.9375rem;
  }
  .g-margin-l-20--lg {
    margin-left: 1.25rem;
  }
  .g-margin-l-25--lg {
    margin-left: 1.5625rem;
  }
  .g-margin-l-30--lg {
    margin-left: 1.875rem;
  }
  .g-margin-l-35--lg {
    margin-left: 2.1875rem;
  }
  .g-margin-l-40--lg {
    margin-left: 2.5rem;
  }
  .g-margin-l-45--lg {
    margin-left: 2.8125rem;
  }
  .g-margin-l-50--lg {
    margin-left: 3.125rem;
  }
  .g-margin-l-55--lg {
    margin-left: 3.4375rem;
  }
  .g-margin-l-60--lg {
    margin-left: 3.75rem;
  }
  .g-margin-l-65--lg {
    margin-left: 4.0625rem;
  }
  .g-margin-l-70--lg {
    margin-left: 4.375rem;
  }
  .g-margin-l-75--lg {
    margin-left: 4.6875rem;
  }
  .g-margin-l-80--lg {
    margin-left: 5rem;
  }
  .g-margin-l-85--lg {
    margin-left: 5.3125rem;
  }
  .g-margin-l-90--lg {
    margin-left: 5.625rem;
  }
  .g-margin-l-95--lg {
    margin-left: 5.9375rem;
  }
  .g-margin-l-100--lg {
    margin-left: 6.25rem;
  }
  .g-margin-l-105--lg {
    margin-left: 6.5625rem;
  }
  .g-margin-l-110--lg {
    margin-left: 6.875rem;
  }
  .g-margin-l-115--lg {
    margin-left: 7.1875rem;
  }
  .g-margin-l-120--lg {
    margin-left: 7.5rem;
  }
  .g-margin-l-125--lg {
    margin-left: 7.8125rem;
  }
  .g-margin-l-130--lg {
    margin-left: 8.125rem;
  }
  .g-margin-l-135--lg {
    margin-left: 8.4375rem;
  }
  .g-margin-l-140--lg {
    margin-left: 8.75rem;
  }
  .g-margin-l-145--lg {
    margin-left: 9.0625rem;
  }
  .g-margin-l-150--lg {
    margin-left: 9.375rem;
  }
}

/*----------------------------------
  Margin Right
------------------------------------*/
.g-margin-r-0--xs {
  margin-right: 0rem;
}

.g-margin-r-5--xs {
  margin-right: 0.3125rem;
}

.g-margin-r-10--xs {
  margin-right: 0.625rem;
}

.g-margin-r-15--xs {
  margin-right: 0.9375rem;
}

.g-margin-r-20--xs {
  margin-right: 1.25rem;
}

.g-margin-r-25--xs {
  margin-right: 1.5625rem;
}

.g-margin-r-30--xs {
  margin-right: 1.875rem;
}

.g-margin-r-35--xs {
  margin-right: 2.1875rem;
}

.g-margin-r-40--xs {
  margin-right: 2.5rem;
}

.g-margin-r-45--xs {
  margin-right: 2.8125rem;
}

.g-margin-r-50--xs {
  margin-right: 3.125rem;
}

.g-margin-r-55--xs {
  margin-right: 3.4375rem;
}

.g-margin-r-60--xs {
  margin-right: 3.75rem;
}

.g-margin-r-65--xs {
  margin-right: 4.0625rem;
}

.g-margin-r-70--xs {
  margin-right: 4.375rem;
}

.g-margin-r-75--xs {
  margin-right: 4.6875rem;
}

.g-margin-r-80--xs {
  margin-right: 5rem;
}

.g-margin-r-85--xs {
  margin-right: 5.3125rem;
}

.g-margin-r-90--xs {
  margin-right: 5.625rem;
}

.g-margin-r-95--xs {
  margin-right: 5.9375rem;
}

.g-margin-r-100--xs {
  margin-right: 6.25rem;
}

.g-margin-r-105--xs {
  margin-right: 6.5625rem;
}

.g-margin-r-110--xs {
  margin-right: 6.875rem;
}

.g-margin-r-115--xs {
  margin-right: 7.1875rem;
}

.g-margin-r-120--xs {
  margin-right: 7.5rem;
}

.g-margin-r-125--xs {
  margin-right: 7.8125rem;
}

.g-margin-r-130--xs {
  margin-right: 8.125rem;
}

.g-margin-r-135--xs {
  margin-right: 8.4375rem;
}

.g-margin-r-140--xs {
  margin-right: 8.75rem;
}

.g-margin-r-145--xs {
  margin-right: 9.0625rem;
}

.g-margin-r-150--xs {
  margin-right: 9.375rem;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-margin-r-0--sm {
    margin-right: 0rem;
  }
  .g-margin-r-5--sm {
    margin-right: 0.3125rem;
  }
  .g-margin-r-10--sm {
    margin-right: 0.625rem;
  }
  .g-margin-r-15--sm {
    margin-right: 0.9375rem;
  }
  .g-margin-r-20--sm {
    margin-right: 1.25rem;
  }
  .g-margin-r-25--sm {
    margin-right: 1.5625rem;
  }
  .g-margin-r-30--sm {
    margin-right: 1.875rem;
  }
  .g-margin-r-35--sm {
    margin-right: 2.1875rem;
  }
  .g-margin-r-40--sm {
    margin-right: 2.5rem;
  }
  .g-margin-r-45--sm {
    margin-right: 2.8125rem;
  }
  .g-margin-r-50--sm {
    margin-right: 3.125rem;
  }
  .g-margin-r-55--sm {
    margin-right: 3.4375rem;
  }
  .g-margin-r-60--sm {
    margin-right: 3.75rem;
  }
  .g-margin-r-65--sm {
    margin-right: 4.0625rem;
  }
  .g-margin-r-70--sm {
    margin-right: 4.375rem;
  }
  .g-margin-r-75--sm {
    margin-right: 4.6875rem;
  }
  .g-margin-r-80--sm {
    margin-right: 5rem;
  }
  .g-margin-r-85--sm {
    margin-right: 5.3125rem;
  }
  .g-margin-r-90--sm {
    margin-right: 5.625rem;
  }
  .g-margin-r-95--sm {
    margin-right: 5.9375rem;
  }
  .g-margin-r-100--sm {
    margin-right: 6.25rem;
  }
  .g-margin-r-105--sm {
    margin-right: 6.5625rem;
  }
  .g-margin-r-110--sm {
    margin-right: 6.875rem;
  }
  .g-margin-r-115--sm {
    margin-right: 7.1875rem;
  }
  .g-margin-r-120--sm {
    margin-right: 7.5rem;
  }
  .g-margin-r-125--sm {
    margin-right: 7.8125rem;
  }
  .g-margin-r-130--sm {
    margin-right: 8.125rem;
  }
  .g-margin-r-135--sm {
    margin-right: 8.4375rem;
  }
  .g-margin-r-140--sm {
    margin-right: 8.75rem;
  }
  .g-margin-r-145--sm {
    margin-right: 9.0625rem;
  }
  .g-margin-r-150--sm {
    margin-right: 9.375rem;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-margin-r-0--md {
    margin-right: 0rem;
  }
  .g-margin-r-5--md {
    margin-right: 0.3125rem;
  }
  .g-margin-r-10--md {
    margin-right: 0.625rem;
  }
  .g-margin-r-15--md {
    margin-right: 0.9375rem;
  }
  .g-margin-r-20--md {
    margin-right: 1.25rem;
  }
  .g-margin-r-25--md {
    margin-right: 1.5625rem;
  }
  .g-margin-r-30--md {
    margin-right: 1.875rem;
  }
  .g-margin-r-35--md {
    margin-right: 2.1875rem;
  }
  .g-margin-r-40--md {
    margin-right: 2.5rem;
  }
  .g-margin-r-45--md {
    margin-right: 2.8125rem;
  }
  .g-margin-r-50--md {
    margin-right: 3.125rem;
  }
  .g-margin-r-55--md {
    margin-right: 3.4375rem;
  }
  .g-margin-r-60--md {
    margin-right: 3.75rem;
  }
  .g-margin-r-65--md {
    margin-right: 4.0625rem;
  }
  .g-margin-r-70--md {
    margin-right: 4.375rem;
  }
  .g-margin-r-75--md {
    margin-right: 4.6875rem;
  }
  .g-margin-r-80--md {
    margin-right: 5rem;
  }
  .g-margin-r-85--md {
    margin-right: 5.3125rem;
  }
  .g-margin-r-90--md {
    margin-right: 5.625rem;
  }
  .g-margin-r-95--md {
    margin-right: 5.9375rem;
  }
  .g-margin-r-100--md {
    margin-right: 6.25rem;
  }
  .g-margin-r-105--md {
    margin-right: 6.5625rem;
  }
  .g-margin-r-110--md {
    margin-right: 6.875rem;
  }
  .g-margin-r-115--md {
    margin-right: 7.1875rem;
  }
  .g-margin-r-120--md {
    margin-right: 7.5rem;
  }
  .g-margin-r-125--md {
    margin-right: 7.8125rem;
  }
  .g-margin-r-130--md {
    margin-right: 8.125rem;
  }
  .g-margin-r-135--md {
    margin-right: 8.4375rem;
  }
  .g-margin-r-140--md {
    margin-right: 8.75rem;
  }
  .g-margin-r-145--md {
    margin-right: 9.0625rem;
  }
  .g-margin-r-150--md {
    margin-right: 9.375rem;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-margin-r-0--lg {
    margin-right: 0rem;
  }
  .g-margin-r-5--lg {
    margin-right: 0.3125rem;
  }
  .g-margin-r-10--lg {
    margin-right: 0.625rem;
  }
  .g-margin-r-15--lg {
    margin-right: 0.9375rem;
  }
  .g-margin-r-20--lg {
    margin-right: 1.25rem;
  }
  .g-margin-r-25--lg {
    margin-right: 1.5625rem;
  }
  .g-margin-r-30--lg {
    margin-right: 1.875rem;
  }
  .g-margin-r-35--lg {
    margin-right: 2.1875rem;
  }
  .g-margin-r-40--lg {
    margin-right: 2.5rem;
  }
  .g-margin-r-45--lg {
    margin-right: 2.8125rem;
  }
  .g-margin-r-50--lg {
    margin-right: 3.125rem;
  }
  .g-margin-r-55--lg {
    margin-right: 3.4375rem;
  }
  .g-margin-r-60--lg {
    margin-right: 3.75rem;
  }
  .g-margin-r-65--lg {
    margin-right: 4.0625rem;
  }
  .g-margin-r-70--lg {
    margin-right: 4.375rem;
  }
  .g-margin-r-75--lg {
    margin-right: 4.6875rem;
  }
  .g-margin-r-80--lg {
    margin-right: 5rem;
  }
  .g-margin-r-85--lg {
    margin-right: 5.3125rem;
  }
  .g-margin-r-90--lg {
    margin-right: 5.625rem;
  }
  .g-margin-r-95--lg {
    margin-right: 5.9375rem;
  }
  .g-margin-r-100--lg {
    margin-right: 6.25rem;
  }
  .g-margin-r-105--lg {
    margin-right: 6.5625rem;
  }
  .g-margin-r-110--lg {
    margin-right: 6.875rem;
  }
  .g-margin-r-115--lg {
    margin-right: 7.1875rem;
  }
  .g-margin-r-120--lg {
    margin-right: 7.5rem;
  }
  .g-margin-r-125--lg {
    margin-right: 7.8125rem;
  }
  .g-margin-r-130--lg {
    margin-right: 8.125rem;
  }
  .g-margin-r-135--lg {
    margin-right: 8.4375rem;
  }
  .g-margin-r-140--lg {
    margin-right: 8.75rem;
  }
  .g-margin-r-145--lg {
    margin-right: 9.0625rem;
  }
  .g-margin-r-150--lg {
    margin-right: 9.375rem;
  }
}

/*----------------------------------
  Margin Top Overlay
------------------------------------*/
.g-margin-t-o-0--xs {
  margin-top: 0rem;
}

.g-margin-t-o-5--xs {
  margin-top: -0.3125rem;
}

.g-margin-t-o-10--xs {
  margin-top: -0.625rem;
}

.g-margin-t-o-15--xs {
  margin-top: -0.9375rem;
}

.g-margin-t-o-20--xs {
  margin-top: -1.25rem;
}

.g-margin-t-o-25--xs {
  margin-top: -1.5625rem;
}

.g-margin-t-o-30--xs {
  margin-top: -1.875rem;
}

.g-margin-t-o-35--xs {
  margin-top: -2.1875rem;
}

.g-margin-t-o-40--xs {
  margin-top: -2.5rem;
}

.g-margin-t-o-45--xs {
  margin-top: -2.8125rem;
}

.g-margin-t-o-50--xs {
  margin-top: -3.125rem;
}

.g-margin-t-o-55--xs {
  margin-top: -3.4375rem;
}

.g-margin-t-o-60--xs {
  margin-top: -3.75rem;
}

.g-margin-t-o-65--xs {
  margin-top: -4.0625rem;
}

.g-margin-t-o-70--xs {
  margin-top: -4.375rem;
}

.g-margin-t-o-75--xs {
  margin-top: -4.6875rem;
}

.g-margin-t-o-80--xs {
  margin-top: -5rem;
}

.g-margin-t-o-85--xs {
  margin-top: -5.3125rem;
}

.g-margin-t-o-90--xs {
  margin-top: -5.625rem;
}

.g-margin-t-o-95--xs {
  margin-top: -5.9375rem;
}

.g-margin-t-o-100--xs {
  margin-top: -6.25rem;
}

.g-margin-t-o-105--xs {
  margin-top: -6.5625rem;
}

.g-margin-t-o-110--xs {
  margin-top: -6.875rem;
}

.g-margin-t-o-115--xs {
  margin-top: -7.1875rem;
}

.g-margin-t-o-120--xs {
  margin-top: -7.5rem;
}

.g-margin-t-o-125--xs {
  margin-top: -7.8125rem;
}

.g-margin-t-o-130--xs {
  margin-top: -8.125rem;
}

.g-margin-t-o-135--xs {
  margin-top: -8.4375rem;
}

.g-margin-t-o-140--xs {
  margin-top: -8.75rem;
}

.g-margin-t-o-145--xs {
  margin-top: -9.0625rem;
}

.g-margin-t-o-150--xs {
  margin-top: -9.375rem;
}

.g-margin-t-o-230--xs {
  margin-top: -14.375rem;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-margin-t-o-0--sm {
    margin-top: 0rem;
  }
  .g-margin-t-o-5--sm {
    margin-top: -0.3125rem;
  }
  .g-margin-t-o-10--sm {
    margin-top: -0.625rem;
  }
  .g-margin-t-o-15--sm {
    margin-top: -0.9375rem;
  }
  .g-margin-t-o-20--sm {
    margin-top: -1.25rem;
  }
  .g-margin-t-o-25--sm {
    margin-top: -1.5625rem;
  }
  .g-margin-t-o-30--sm {
    margin-top: -1.875rem;
  }
  .g-margin-t-o-35--sm {
    margin-top: -2.1875rem;
  }
  .g-margin-t-o-40--sm {
    margin-top: -2.5rem;
  }
  .g-margin-t-o-45--sm {
    margin-top: -2.8125rem;
  }
  .g-margin-t-o-50--sm {
    margin-top: -3.125rem;
  }
  .g-margin-t-o-55--sm {
    margin-top: -3.4375rem;
  }
  .g-margin-t-o-60--sm {
    margin-top: -3.75rem;
  }
  .g-margin-t-o-65--sm {
    margin-top: -4.0625rem;
  }
  .g-margin-t-o-70--sm {
    margin-top: -4.375rem;
  }
  .g-margin-t-o-75--sm {
    margin-top: -4.6875rem;
  }
  .g-margin-t-o-80--sm {
    margin-top: -5rem;
  }
  .g-margin-t-o-85--sm {
    margin-top: -5.3125rem;
  }
  .g-margin-t-o-90--sm {
    margin-top: -5.625rem;
  }
  .g-margin-t-o-95--sm {
    margin-top: -5.9375rem;
  }
  .g-margin-t-o-100--sm {
    margin-top: -6.25rem;
  }
  .g-margin-t-o-105--sm {
    margin-top: -6.5625rem;
  }
  .g-margin-t-o-110--sm {
    margin-top: -6.875rem;
  }
  .g-margin-t-o-115--sm {
    margin-top: -7.1875rem;
  }
  .g-margin-t-o-120--sm {
    margin-top: -7.5rem;
  }
  .g-margin-t-o-125--sm {
    margin-top: -7.8125rem;
  }
  .g-margin-t-o-130--sm {
    margin-top: -8.125rem;
  }
  .g-margin-t-o-135--sm {
    margin-top: -8.4375rem;
  }
  .g-margin-t-o-140--sm {
    margin-top: -8.75rem;
  }
  .g-margin-t-o-145--sm {
    margin-top: -9.0625rem;
  }
  .g-margin-t-o-150--sm {
    margin-top: -9.375rem;
  }
  .g-margin-t-o-230--sm {
    margin-top: -14.375rem;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-margin-t-o-0--md {
    margin-top: 0rem;
  }
  .g-margin-t-o-5--md {
    margin-top: -0.3125rem;
  }
  .g-margin-t-o-10--md {
    margin-top: -0.625rem;
  }
  .g-margin-t-o-15--md {
    margin-top: -0.9375rem;
  }
  .g-margin-t-o-20--md {
    margin-top: -1.25rem;
  }
  .g-margin-t-o-25--md {
    margin-top: -1.5625rem;
  }
  .g-margin-t-o-30--md {
    margin-top: -1.875rem;
  }
  .g-margin-t-o-35--md {
    margin-top: -2.1875rem;
  }
  .g-margin-t-o-40--md {
    margin-top: -2.5rem;
  }
  .g-margin-t-o-45--md {
    margin-top: -2.8125rem;
  }
  .g-margin-t-o-50--md {
    margin-top: -3.125rem;
  }
  .g-margin-t-o-55--md {
    margin-top: -3.4375rem;
  }
  .g-margin-t-o-60--md {
    margin-top: -3.75rem;
  }
  .g-margin-t-o-65--md {
    margin-top: -4.0625rem;
  }
  .g-margin-t-o-70--md {
    margin-top: -4.375rem;
  }
  .g-margin-t-o-75--md {
    margin-top: -4.6875rem;
  }
  .g-margin-t-o-80--md {
    margin-top: -5rem;
  }
  .g-margin-t-o-85--md {
    margin-top: -5.3125rem;
  }
  .g-margin-t-o-90--md {
    margin-top: -5.625rem;
  }
  .g-margin-t-o-95--md {
    margin-top: -5.9375rem;
  }
  .g-margin-t-o-100--md {
    margin-top: -6.25rem;
  }
  .g-margin-t-o-105--md {
    margin-top: -6.5625rem;
  }
  .g-margin-t-o-110--md {
    margin-top: -6.875rem;
  }
  .g-margin-t-o-115--md {
    margin-top: -7.1875rem;
  }
  .g-margin-t-o-120--md {
    margin-top: -7.5rem;
  }
  .g-margin-t-o-125--md {
    margin-top: -7.8125rem;
  }
  .g-margin-t-o-130--md {
    margin-top: -8.125rem;
  }
  .g-margin-t-o-135--md {
    margin-top: -8.4375rem;
  }
  .g-margin-t-o-140--md {
    margin-top: -8.75rem;
  }
  .g-margin-t-o-145--md {
    margin-top: -9.0625rem;
  }
  .g-margin-t-o-150--md {
    margin-top: -9.375rem;
  }
  .g-margin-t-o-230--md {
    margin-top: -14.375rem;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-margin-t-o-0--lg {
    margin-top: 0rem;
  }
  .g-margin-t-o-5--lg {
    margin-top: -0.3125rem;
  }
  .g-margin-t-o-10--lg {
    margin-top: -0.625rem;
  }
  .g-margin-t-o-15--lg {
    margin-top: -0.9375rem;
  }
  .g-margin-t-o-20--lg {
    margin-top: -1.25rem;
  }
  .g-margin-t-o-25--lg {
    margin-top: -1.5625rem;
  }
  .g-margin-t-o-30--lg {
    margin-top: -1.875rem;
  }
  .g-margin-t-o-35--lg {
    margin-top: -2.1875rem;
  }
  .g-margin-t-o-40--lg {
    margin-top: -2.5rem;
  }
  .g-margin-t-o-45--lg {
    margin-top: -2.8125rem;
  }
  .g-margin-t-o-50--lg {
    margin-top: -3.125rem;
  }
  .g-margin-t-o-55--lg {
    margin-top: -3.4375rem;
  }
  .g-margin-t-o-60--lg {
    margin-top: -3.75rem;
  }
  .g-margin-t-o-65--lg {
    margin-top: -4.0625rem;
  }
  .g-margin-t-o-70--lg {
    margin-top: -4.375rem;
  }
  .g-margin-t-o-75--lg {
    margin-top: -4.6875rem;
  }
  .g-margin-t-o-80--lg {
    margin-top: -5rem;
  }
  .g-margin-t-o-85--lg {
    margin-top: -5.3125rem;
  }
  .g-margin-t-o-90--lg {
    margin-top: -5.625rem;
  }
  .g-margin-t-o-95--lg {
    margin-top: -5.9375rem;
  }
  .g-margin-t-o-100--lg {
    margin-top: -6.25rem;
  }
  .g-margin-t-o-105--lg {
    margin-top: -6.5625rem;
  }
  .g-margin-t-o-110--lg {
    margin-top: -6.875rem;
  }
  .g-margin-t-o-115--lg {
    margin-top: -7.1875rem;
  }
  .g-margin-t-o-120--lg {
    margin-top: -7.5rem;
  }
  .g-margin-t-o-125--lg {
    margin-top: -7.8125rem;
  }
  .g-margin-t-o-130--lg {
    margin-top: -8.125rem;
  }
  .g-margin-t-o-135--lg {
    margin-top: -8.4375rem;
  }
  .g-margin-t-o-140--lg {
    margin-top: -8.75rem;
  }
  .g-margin-t-o-145--lg {
    margin-top: -9.0625rem;
  }
  .g-margin-t-o-150--lg {
    margin-top: -9.375rem;
  }
  .g-margin-t-o-230--lg {
    margin-top: -14.375rem;
  }
}

/*----------------------------------
  Margin Left Overlay
------------------------------------*/
.g-margin-l-o-0--xs {
  margin-left: 0rem;
}

.g-margin-l-o-5--xs {
  margin-left: -0.3125rem;
}

.g-margin-l-o-10--xs {
  margin-left: -0.625rem;
}

.g-margin-l-o-15--xs {
  margin-left: -0.9375rem;
}

.g-margin-l-o-20--xs {
  margin-left: -1.25rem;
}

.g-margin-l-o-25--xs {
  margin-left: -1.5625rem;
}

.g-margin-l-o-30--xs {
  margin-left: -1.875rem;
}

.g-margin-l-o-35--xs {
  margin-left: -2.1875rem;
}

.g-margin-l-o-40--xs {
  margin-left: -2.5rem;
}

.g-margin-l-o-45--xs {
  margin-left: -2.8125rem;
}

.g-margin-l-o-50--xs {
  margin-left: -3.125rem;
}

.g-margin-l-o-55--xs {
  margin-left: -3.4375rem;
}

.g-margin-l-o-60--xs {
  margin-left: -3.75rem;
}

.g-margin-l-o-65--xs {
  margin-left: -4.0625rem;
}

.g-margin-l-o-70--xs {
  margin-left: -4.375rem;
}

.g-margin-l-o-75--xs {
  margin-left: -4.6875rem;
}

.g-margin-l-o-80--xs {
  margin-left: -5rem;
}

.g-margin-l-o-85--xs {
  margin-left: -5.3125rem;
}

.g-margin-l-o-90--xs {
  margin-left: -5.625rem;
}

.g-margin-l-o-95--xs {
  margin-left: -5.9375rem;
}

.g-margin-l-o-100--xs {
  margin-left: -6.25rem;
}

.g-margin-l-o-105--xs {
  margin-left: -6.5625rem;
}

.g-margin-l-o-110--xs {
  margin-left: -6.875rem;
}

.g-margin-l-o-115--xs {
  margin-left: -7.1875rem;
}

.g-margin-l-o-120--xs {
  margin-left: -7.5rem;
}

.g-margin-l-o-125--xs {
  margin-left: -7.8125rem;
}

.g-margin-l-o-130--xs {
  margin-left: -8.125rem;
}

.g-margin-l-o-135--xs {
  margin-left: -8.4375rem;
}

.g-margin-l-o-140--xs {
  margin-left: -8.75rem;
}

.g-margin-l-o-145--xs {
  margin-left: -9.0625rem;
}

.g-margin-l-o-150--xs {
  margin-left: -9.375rem;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-margin-l-o-0--sm {
    margin-left: 0rem;
  }
  .g-margin-l-o-5--sm {
    margin-left: -0.3125rem;
  }
  .g-margin-l-o-10--sm {
    margin-left: -0.625rem;
  }
  .g-margin-l-o-15--sm {
    margin-left: -0.9375rem;
  }
  .g-margin-l-o-20--sm {
    margin-left: -1.25rem;
  }
  .g-margin-l-o-25--sm {
    margin-left: -1.5625rem;
  }
  .g-margin-l-o-30--sm {
    margin-left: -1.875rem;
  }
  .g-margin-l-o-35--sm {
    margin-left: -2.1875rem;
  }
  .g-margin-l-o-40--sm {
    margin-left: -2.5rem;
  }
  .g-margin-l-o-45--sm {
    margin-left: -2.8125rem;
  }
  .g-margin-l-o-50--sm {
    margin-left: -3.125rem;
  }
  .g-margin-l-o-55--sm {
    margin-left: -3.4375rem;
  }
  .g-margin-l-o-60--sm {
    margin-left: -3.75rem;
  }
  .g-margin-l-o-65--sm {
    margin-left: -4.0625rem;
  }
  .g-margin-l-o-70--sm {
    margin-left: -4.375rem;
  }
  .g-margin-l-o-75--sm {
    margin-left: -4.6875rem;
  }
  .g-margin-l-o-80--sm {
    margin-left: -5rem;
  }
  .g-margin-l-o-85--sm {
    margin-left: -5.3125rem;
  }
  .g-margin-l-o-90--sm {
    margin-left: -5.625rem;
  }
  .g-margin-l-o-95--sm {
    margin-left: -5.9375rem;
  }
  .g-margin-l-o-100--sm {
    margin-left: -6.25rem;
  }
  .g-margin-l-o-105--sm {
    margin-left: -6.5625rem;
  }
  .g-margin-l-o-110--sm {
    margin-left: -6.875rem;
  }
  .g-margin-l-o-115--sm {
    margin-left: -7.1875rem;
  }
  .g-margin-l-o-120--sm {
    margin-left: -7.5rem;
  }
  .g-margin-l-o-125--sm {
    margin-left: -7.8125rem;
  }
  .g-margin-l-o-130--sm {
    margin-left: -8.125rem;
  }
  .g-margin-l-o-135--sm {
    margin-left: -8.4375rem;
  }
  .g-margin-l-o-140--sm {
    margin-left: -8.75rem;
  }
  .g-margin-l-o-145--sm {
    margin-left: -9.0625rem;
  }
  .g-margin-l-o-150--sm {
    margin-left: -9.375rem;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-margin-l-o-0--md {
    margin-left: 0rem;
  }
  .g-margin-l-o-5--md {
    margin-left: -0.3125rem;
  }
  .g-margin-l-o-10--md {
    margin-left: -0.625rem;
  }
  .g-margin-l-o-15--md {
    margin-left: -0.9375rem;
  }
  .g-margin-l-o-20--md {
    margin-left: -1.25rem;
  }
  .g-margin-l-o-25--md {
    margin-left: -1.5625rem;
  }
  .g-margin-l-o-30--md {
    margin-left: -1.875rem;
  }
  .g-margin-l-o-35--md {
    margin-left: -2.1875rem;
  }
  .g-margin-l-o-40--md {
    margin-left: -2.5rem;
  }
  .g-margin-l-o-45--md {
    margin-left: -2.8125rem;
  }
  .g-margin-l-o-50--md {
    margin-left: -3.125rem;
  }
  .g-margin-l-o-55--md {
    margin-left: -3.4375rem;
  }
  .g-margin-l-o-60--md {
    margin-left: -3.75rem;
  }
  .g-margin-l-o-65--md {
    margin-left: -4.0625rem;
  }
  .g-margin-l-o-70--md {
    margin-left: -4.375rem;
  }
  .g-margin-l-o-75--md {
    margin-left: -4.6875rem;
  }
  .g-margin-l-o-80--md {
    margin-left: -5rem;
  }
  .g-margin-l-o-85--md {
    margin-left: -5.3125rem;
  }
  .g-margin-l-o-90--md {
    margin-left: -5.625rem;
  }
  .g-margin-l-o-95--md {
    margin-left: -5.9375rem;
  }
  .g-margin-l-o-100--md {
    margin-left: -6.25rem;
  }
  .g-margin-l-o-105--md {
    margin-left: -6.5625rem;
  }
  .g-margin-l-o-110--md {
    margin-left: -6.875rem;
  }
  .g-margin-l-o-115--md {
    margin-left: -7.1875rem;
  }
  .g-margin-l-o-120--md {
    margin-left: -7.5rem;
  }
  .g-margin-l-o-125--md {
    margin-left: -7.8125rem;
  }
  .g-margin-l-o-130--md {
    margin-left: -8.125rem;
  }
  .g-margin-l-o-135--md {
    margin-left: -8.4375rem;
  }
  .g-margin-l-o-140--md {
    margin-left: -8.75rem;
  }
  .g-margin-l-o-145--md {
    margin-left: -9.0625rem;
  }
  .g-margin-l-o-150--md {
    margin-left: -9.375rem;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-margin-l-o-0--lg {
    margin-left: 0rem;
  }
  .g-margin-l-o-5--lg {
    margin-left: -0.3125rem;
  }
  .g-margin-l-o-10--lg {
    margin-left: -0.625rem;
  }
  .g-margin-l-o-15--lg {
    margin-left: -0.9375rem;
  }
  .g-margin-l-o-20--lg {
    margin-left: -1.25rem;
  }
  .g-margin-l-o-25--lg {
    margin-left: -1.5625rem;
  }
  .g-margin-l-o-30--lg {
    margin-left: -1.875rem;
  }
  .g-margin-l-o-35--lg {
    margin-left: -2.1875rem;
  }
  .g-margin-l-o-40--lg {
    margin-left: -2.5rem;
  }
  .g-margin-l-o-45--lg {
    margin-left: -2.8125rem;
  }
  .g-margin-l-o-50--lg {
    margin-left: -3.125rem;
  }
  .g-margin-l-o-55--lg {
    margin-left: -3.4375rem;
  }
  .g-margin-l-o-60--lg {
    margin-left: -3.75rem;
  }
  .g-margin-l-o-65--lg {
    margin-left: -4.0625rem;
  }
  .g-margin-l-o-70--lg {
    margin-left: -4.375rem;
  }
  .g-margin-l-o-75--lg {
    margin-left: -4.6875rem;
  }
  .g-margin-l-o-80--lg {
    margin-left: -5rem;
  }
  .g-margin-l-o-85--lg {
    margin-left: -5.3125rem;
  }
  .g-margin-l-o-90--lg {
    margin-left: -5.625rem;
  }
  .g-margin-l-o-95--lg {
    margin-left: -5.9375rem;
  }
  .g-margin-l-o-100--lg {
    margin-left: -6.25rem;
  }
  .g-margin-l-o-105--lg {
    margin-left: -6.5625rem;
  }
  .g-margin-l-o-110--lg {
    margin-left: -6.875rem;
  }
  .g-margin-l-o-115--lg {
    margin-left: -7.1875rem;
  }
  .g-margin-l-o-120--lg {
    margin-left: -7.5rem;
  }
  .g-margin-l-o-125--lg {
    margin-left: -7.8125rem;
  }
  .g-margin-l-o-130--lg {
    margin-left: -8.125rem;
  }
  .g-margin-l-o-135--lg {
    margin-left: -8.4375rem;
  }
  .g-margin-l-o-140--lg {
    margin-left: -8.75rem;
  }
  .g-margin-l-o-145--lg {
    margin-left: -9.0625rem;
  }
  .g-margin-l-o-150--lg {
    margin-left: -9.375rem;
  }
}

/*----------------------------------
  Margin Right Overlay
------------------------------------*/
.g-margin-r-o-0--xs {
  margin-right: 0rem;
}

.g-margin-r-o-5--xs {
  margin-right: -0.3125rem;
}

.g-margin-r-o-10--xs {
  margin-right: -0.625rem;
}

.g-margin-r-o-15--xs {
  margin-right: -0.9375rem;
}

.g-margin-r-o-20--xs {
  margin-right: -1.25rem;
}

.g-margin-r-o-25--xs {
  margin-right: -1.5625rem;
}

.g-margin-r-o-30--xs {
  margin-right: -1.875rem;
}

.g-margin-r-o-35--xs {
  margin-right: -2.1875rem;
}

.g-margin-r-o-40--xs {
  margin-right: -2.5rem;
}

.g-margin-r-o-45--xs {
  margin-right: -2.8125rem;
}

.g-margin-r-o-50--xs {
  margin-right: -3.125rem;
}

.g-margin-r-o-55--xs {
  margin-right: -3.4375rem;
}

.g-margin-r-o-60--xs {
  margin-right: -3.75rem;
}

.g-margin-r-o-65--xs {
  margin-right: -4.0625rem;
}

.g-margin-r-o-70--xs {
  margin-right: -4.375rem;
}

.g-margin-r-o-75--xs {
  margin-right: -4.6875rem;
}

.g-margin-r-o-80--xs {
  margin-right: -5rem;
}

.g-margin-r-o-85--xs {
  margin-right: -5.3125rem;
}

.g-margin-r-o-90--xs {
  margin-right: -5.625rem;
}

.g-margin-r-o-95--xs {
  margin-right: -5.9375rem;
}

.g-margin-r-o-100--xs {
  margin-right: -6.25rem;
}

.g-margin-r-o-105--xs {
  margin-right: -6.5625rem;
}

.g-margin-r-o-110--xs {
  margin-right: -6.875rem;
}

.g-margin-r-o-115--xs {
  margin-right: -7.1875rem;
}

.g-margin-r-o-120--xs {
  margin-right: -7.5rem;
}

.g-margin-r-o-125--xs {
  margin-right: -7.8125rem;
}

.g-margin-r-o-130--xs {
  margin-right: -8.125rem;
}

.g-margin-r-o-135--xs {
  margin-right: -8.4375rem;
}

.g-margin-r-o-140--xs {
  margin-right: -8.75rem;
}

.g-margin-r-o-145--xs {
  margin-right: -9.0625rem;
}

.g-margin-r-o-150--xs {
  margin-right: -9.375rem;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-margin-r-o-0--sm {
    margin-right: 0rem;
  }
  .g-margin-r-o-5--sm {
    margin-right: -0.3125rem;
  }
  .g-margin-r-o-10--sm {
    margin-right: -0.625rem;
  }
  .g-margin-r-o-15--sm {
    margin-right: -0.9375rem;
  }
  .g-margin-r-o-20--sm {
    margin-right: -1.25rem;
  }
  .g-margin-r-o-25--sm {
    margin-right: -1.5625rem;
  }
  .g-margin-r-o-30--sm {
    margin-right: -1.875rem;
  }
  .g-margin-r-o-35--sm {
    margin-right: -2.1875rem;
  }
  .g-margin-r-o-40--sm {
    margin-right: -2.5rem;
  }
  .g-margin-r-o-45--sm {
    margin-right: -2.8125rem;
  }
  .g-margin-r-o-50--sm {
    margin-right: -3.125rem;
  }
  .g-margin-r-o-55--sm {
    margin-right: -3.4375rem;
  }
  .g-margin-r-o-60--sm {
    margin-right: -3.75rem;
  }
  .g-margin-r-o-65--sm {
    margin-right: -4.0625rem;
  }
  .g-margin-r-o-70--sm {
    margin-right: -4.375rem;
  }
  .g-margin-r-o-75--sm {
    margin-right: -4.6875rem;
  }
  .g-margin-r-o-80--sm {
    margin-right: -5rem;
  }
  .g-margin-r-o-85--sm {
    margin-right: -5.3125rem;
  }
  .g-margin-r-o-90--sm {
    margin-right: -5.625rem;
  }
  .g-margin-r-o-95--sm {
    margin-right: -5.9375rem;
  }
  .g-margin-r-o-100--sm {
    margin-right: -6.25rem;
  }
  .g-margin-r-o-105--sm {
    margin-right: -6.5625rem;
  }
  .g-margin-r-o-110--sm {
    margin-right: -6.875rem;
  }
  .g-margin-r-o-115--sm {
    margin-right: -7.1875rem;
  }
  .g-margin-r-o-120--sm {
    margin-right: -7.5rem;
  }
  .g-margin-r-o-125--sm {
    margin-right: -7.8125rem;
  }
  .g-margin-r-o-130--sm {
    margin-right: -8.125rem;
  }
  .g-margin-r-o-135--sm {
    margin-right: -8.4375rem;
  }
  .g-margin-r-o-140--sm {
    margin-right: -8.75rem;
  }
  .g-margin-r-o-145--sm {
    margin-right: -9.0625rem;
  }
  .g-margin-r-o-150--sm {
    margin-right: -9.375rem;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-margin-r-o-0--md {
    margin-right: 0rem;
  }
  .g-margin-r-o-5--md {
    margin-right: -0.3125rem;
  }
  .g-margin-r-o-10--md {
    margin-right: -0.625rem;
  }
  .g-margin-r-o-15--md {
    margin-right: -0.9375rem;
  }
  .g-margin-r-o-20--md {
    margin-right: -1.25rem;
  }
  .g-margin-r-o-25--md {
    margin-right: -1.5625rem;
  }
  .g-margin-r-o-30--md {
    margin-right: -1.875rem;
  }
  .g-margin-r-o-35--md {
    margin-right: -2.1875rem;
  }
  .g-margin-r-o-40--md {
    margin-right: -2.5rem;
  }
  .g-margin-r-o-45--md {
    margin-right: -2.8125rem;
  }
  .g-margin-r-o-50--md {
    margin-right: -3.125rem;
  }
  .g-margin-r-o-55--md {
    margin-right: -3.4375rem;
  }
  .g-margin-r-o-60--md {
    margin-right: -3.75rem;
  }
  .g-margin-r-o-65--md {
    margin-right: -4.0625rem;
  }
  .g-margin-r-o-70--md {
    margin-right: -4.375rem;
  }
  .g-margin-r-o-75--md {
    margin-right: -4.6875rem;
  }
  .g-margin-r-o-80--md {
    margin-right: -5rem;
  }
  .g-margin-r-o-85--md {
    margin-right: -5.3125rem;
  }
  .g-margin-r-o-90--md {
    margin-right: -5.625rem;
  }
  .g-margin-r-o-95--md {
    margin-right: -5.9375rem;
  }
  .g-margin-r-o-100--md {
    margin-right: -6.25rem;
  }
  .g-margin-r-o-105--md {
    margin-right: -6.5625rem;
  }
  .g-margin-r-o-110--md {
    margin-right: -6.875rem;
  }
  .g-margin-r-o-115--md {
    margin-right: -7.1875rem;
  }
  .g-margin-r-o-120--md {
    margin-right: -7.5rem;
  }
  .g-margin-r-o-125--md {
    margin-right: -7.8125rem;
  }
  .g-margin-r-o-130--md {
    margin-right: -8.125rem;
  }
  .g-margin-r-o-135--md {
    margin-right: -8.4375rem;
  }
  .g-margin-r-o-140--md {
    margin-right: -8.75rem;
  }
  .g-margin-r-o-145--md {
    margin-right: -9.0625rem;
  }
  .g-margin-r-o-150--md {
    margin-right: -9.375rem;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-margin-r-o-0--lg {
    margin-right: 0rem;
  }
  .g-margin-r-o-5--lg {
    margin-right: -0.3125rem;
  }
  .g-margin-r-o-10--lg {
    margin-right: -0.625rem;
  }
  .g-margin-r-o-15--lg {
    margin-right: -0.9375rem;
  }
  .g-margin-r-o-20--lg {
    margin-right: -1.25rem;
  }
  .g-margin-r-o-25--lg {
    margin-right: -1.5625rem;
  }
  .g-margin-r-o-30--lg {
    margin-right: -1.875rem;
  }
  .g-margin-r-o-35--lg {
    margin-right: -2.1875rem;
  }
  .g-margin-r-o-40--lg {
    margin-right: -2.5rem;
  }
  .g-margin-r-o-45--lg {
    margin-right: -2.8125rem;
  }
  .g-margin-r-o-50--lg {
    margin-right: -3.125rem;
  }
  .g-margin-r-o-55--lg {
    margin-right: -3.4375rem;
  }
  .g-margin-r-o-60--lg {
    margin-right: -3.75rem;
  }
  .g-margin-r-o-65--lg {
    margin-right: -4.0625rem;
  }
  .g-margin-r-o-70--lg {
    margin-right: -4.375rem;
  }
  .g-margin-r-o-75--lg {
    margin-right: -4.6875rem;
  }
  .g-margin-r-o-80--lg {
    margin-right: -5rem;
  }
  .g-margin-r-o-85--lg {
    margin-right: -5.3125rem;
  }
  .g-margin-r-o-90--lg {
    margin-right: -5.625rem;
  }
  .g-margin-r-o-95--lg {
    margin-right: -5.9375rem;
  }
  .g-margin-r-o-100--lg {
    margin-right: -6.25rem;
  }
  .g-margin-r-o-105--lg {
    margin-right: -6.5625rem;
  }
  .g-margin-r-o-110--lg {
    margin-right: -6.875rem;
  }
  .g-margin-r-o-115--lg {
    margin-right: -7.1875rem;
  }
  .g-margin-r-o-120--lg {
    margin-right: -7.5rem;
  }
  .g-margin-r-o-125--lg {
    margin-right: -7.8125rem;
  }
  .g-margin-r-o-130--lg {
    margin-right: -8.125rem;
  }
  .g-margin-r-o-135--lg {
    margin-right: -8.4375rem;
  }
  .g-margin-r-o-140--lg {
    margin-right: -8.75rem;
  }
  .g-margin-r-o-145--lg {
    margin-right: -9.0625rem;
  }
  .g-margin-r-o-150--lg {
    margin-right: -9.375rem;
  }
}

/*----------------------------------
  Padding Left
------------------------------------*/
.g-padding-l-0--xs {
  padding-left: 0rem;
}

.g-padding-l-5--xs {
  padding-left: 0.3125rem;
}

.g-padding-l-10--xs {
  padding-left: 0.625rem;
}

.g-padding-l-15--xs {
  padding-left: 0.9375rem;
}

.g-padding-l-20--xs {
  padding-left: 1.25rem;
}

.g-padding-l-25--xs {
  padding-left: 1.5625rem;
}

.g-padding-l-30--xs {
  padding-left: 1.875rem;
}

.g-padding-l-35--xs {
  padding-left: 2.1875rem;
}

.g-padding-l-40--xs {
  padding-left: 2.5rem;
}

.g-padding-l-45--xs {
  padding-left: 2.8125rem;
}

.g-padding-l-50--xs {
  padding-left: 3.125rem;
}

.g-padding-l-55--xs {
  padding-left: 3.4375rem;
}

.g-padding-l-60--xs {
  padding-left: 3.75rem;
}

.g-padding-l-65--xs {
  padding-left: 4.0625rem;
}

.g-padding-l-70--xs {
  padding-left: 4.375rem;
}

.g-padding-l-75--xs {
  padding-left: 4.6875rem;
}

.g-padding-l-80--xs {
  padding-left: 5rem;
}

.g-padding-l-85--xs {
  padding-left: 5.3125rem;
}

.g-padding-l-90--xs {
  padding-left: 5.625rem;
}

.g-padding-l-95--xs {
  padding-left: 5.9375rem;
}

.g-padding-l-100--xs {
  padding-left: 6.25rem;
}

.g-padding-l-105--xs {
  padding-left: 6.5625rem;
}

.g-padding-l-110--xs {
  padding-left: 6.875rem;
}

.g-padding-l-115--xs {
  padding-left: 7.1875rem;
}

.g-padding-l-120--xs {
  padding-left: 7.5rem;
}

.g-padding-l-125--xs {
  padding-left: 7.8125rem;
}

.g-padding-l-130--xs {
  padding-left: 8.125rem;
}

.g-padding-l-135--xs {
  padding-left: 8.4375rem;
}

.g-padding-l-140--xs {
  padding-left: 8.75rem;
}

.g-padding-l-145--xs {
  padding-left: 9.0625rem;
}

.g-padding-l-150--xs {
  padding-left: 9.375rem;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-padding-l-0-sm {
    padding-left: 0rem;
  }
  .g-padding-l-5-sm {
    padding-left: 0.3125rem;
  }
  .g-padding-l-10-sm {
    padding-left: 0.625rem;
  }
  .g-padding-l-15-sm {
    padding-left: 0.9375rem;
  }
  .g-padding-l-20-sm {
    padding-left: 1.25rem;
  }
  .g-padding-l-25-sm {
    padding-left: 1.5625rem;
  }
  .g-padding-l-30-sm {
    padding-left: 1.875rem;
  }
  .g-padding-l-35-sm {
    padding-left: 2.1875rem;
  }
  .g-padding-l-40-sm {
    padding-left: 2.5rem;
  }
  .g-padding-l-45-sm {
    padding-left: 2.8125rem;
  }
  .g-padding-l-50-sm {
    padding-left: 3.125rem;
  }
  .g-padding-l-55-sm {
    padding-left: 3.4375rem;
  }
  .g-padding-l-60-sm {
    padding-left: 3.75rem;
  }
  .g-padding-l-65-sm {
    padding-left: 4.0625rem;
  }
  .g-padding-l-70-sm {
    padding-left: 4.375rem;
  }
  .g-padding-l-75-sm {
    padding-left: 4.6875rem;
  }
  .g-padding-l-80-sm {
    padding-left: 5rem;
  }
  .g-padding-l-85-sm {
    padding-left: 5.3125rem;
  }
  .g-padding-l-90-sm {
    padding-left: 5.625rem;
  }
  .g-padding-l-95-sm {
    padding-left: 5.9375rem;
  }
  .g-padding-l-100-sm {
    padding-left: 6.25rem;
  }
  .g-padding-l-105-sm {
    padding-left: 6.5625rem;
  }
  .g-padding-l-110-sm {
    padding-left: 6.875rem;
  }
  .g-padding-l-115-sm {
    padding-left: 7.1875rem;
  }
  .g-padding-l-120-sm {
    padding-left: 7.5rem;
  }
  .g-padding-l-125-sm {
    padding-left: 7.8125rem;
  }
  .g-padding-l-130-sm {
    padding-left: 8.125rem;
  }
  .g-padding-l-135-sm {
    padding-left: 8.4375rem;
  }
  .g-padding-l-140-sm {
    padding-left: 8.75rem;
  }
  .g-padding-l-145-sm {
    padding-left: 9.0625rem;
  }
  .g-padding-l-150-sm {
    padding-left: 9.375rem;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-padding-l-0-md {
    padding-left: 0rem;
  }
  .g-padding-l-5-md {
    padding-left: 0.3125rem;
  }
  .g-padding-l-10-md {
    padding-left: 0.625rem;
  }
  .g-padding-l-15-md {
    padding-left: 0.9375rem;
  }
  .g-padding-l-20-md {
    padding-left: 1.25rem;
  }
  .g-padding-l-25-md {
    padding-left: 1.5625rem;
  }
  .g-padding-l-30-md {
    padding-left: 1.875rem;
  }
  .g-padding-l-35-md {
    padding-left: 2.1875rem;
  }
  .g-padding-l-40-md {
    padding-left: 2.5rem;
  }
  .g-padding-l-45-md {
    padding-left: 2.8125rem;
  }
  .g-padding-l-50-md {
    padding-left: 3.125rem;
  }
  .g-padding-l-55-md {
    padding-left: 3.4375rem;
  }
  .g-padding-l-60-md {
    padding-left: 3.75rem;
  }
  .g-padding-l-65-md {
    padding-left: 4.0625rem;
  }
  .g-padding-l-70-md {
    padding-left: 4.375rem;
  }
  .g-padding-l-75-md {
    padding-left: 4.6875rem;
  }
  .g-padding-l-80-md {
    padding-left: 5rem;
  }
  .g-padding-l-85-md {
    padding-left: 5.3125rem;
  }
  .g-padding-l-90-md {
    padding-left: 5.625rem;
  }
  .g-padding-l-95-md {
    padding-left: 5.9375rem;
  }
  .g-padding-l-100-md {
    padding-left: 6.25rem;
  }
  .g-padding-l-105-md {
    padding-left: 6.5625rem;
  }
  .g-padding-l-110-md {
    padding-left: 6.875rem;
  }
  .g-padding-l-115-md {
    padding-left: 7.1875rem;
  }
  .g-padding-l-120-md {
    padding-left: 7.5rem;
  }
  .g-padding-l-125-md {
    padding-left: 7.8125rem;
  }
  .g-padding-l-130-md {
    padding-left: 8.125rem;
  }
  .g-padding-l-135-md {
    padding-left: 8.4375rem;
  }
  .g-padding-l-140-md {
    padding-left: 8.75rem;
  }
  .g-padding-l-145-md {
    padding-left: 9.0625rem;
  }
  .g-padding-l-150-md {
    padding-left: 9.375rem;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-padding-l-0-lg {
    padding-left: 0rem;
  }
  .g-padding-l-5-lg {
    padding-left: 0.3125rem;
  }
  .g-padding-l-10-lg {
    padding-left: 0.625rem;
  }
  .g-padding-l-15-lg {
    padding-left: 0.9375rem;
  }
  .g-padding-l-20-lg {
    padding-left: 1.25rem;
  }
  .g-padding-l-25-lg {
    padding-left: 1.5625rem;
  }
  .g-padding-l-30-lg {
    padding-left: 1.875rem;
  }
  .g-padding-l-35-lg {
    padding-left: 2.1875rem;
  }
  .g-padding-l-40-lg {
    padding-left: 2.5rem;
  }
  .g-padding-l-45-lg {
    padding-left: 2.8125rem;
  }
  .g-padding-l-50-lg {
    padding-left: 3.125rem;
  }
  .g-padding-l-55-lg {
    padding-left: 3.4375rem;
  }
  .g-padding-l-60-lg {
    padding-left: 3.75rem;
  }
  .g-padding-l-65-lg {
    padding-left: 4.0625rem;
  }
  .g-padding-l-70-lg {
    padding-left: 4.375rem;
  }
  .g-padding-l-75-lg {
    padding-left: 4.6875rem;
  }
  .g-padding-l-80-lg {
    padding-left: 5rem;
  }
  .g-padding-l-85-lg {
    padding-left: 5.3125rem;
  }
  .g-padding-l-90-lg {
    padding-left: 5.625rem;
  }
  .g-padding-l-95-lg {
    padding-left: 5.9375rem;
  }
  .g-padding-l-100-lg {
    padding-left: 6.25rem;
  }
  .g-padding-l-105-lg {
    padding-left: 6.5625rem;
  }
  .g-padding-l-110-lg {
    padding-left: 6.875rem;
  }
  .g-padding-l-115-lg {
    padding-left: 7.1875rem;
  }
  .g-padding-l-120-lg {
    padding-left: 7.5rem;
  }
  .g-padding-l-125-lg {
    padding-left: 7.8125rem;
  }
  .g-padding-l-130-lg {
    padding-left: 8.125rem;
  }
  .g-padding-l-135-lg {
    padding-left: 8.4375rem;
  }
  .g-padding-l-140-lg {
    padding-left: 8.75rem;
  }
  .g-padding-l-145-lg {
    padding-left: 9.0625rem;
  }
  .g-padding-l-150-lg {
    padding-left: 9.375rem;
  }
}

/*----------------------------------
  Padding Right
------------------------------------*/
.g-padding-r-0--xs {
  padding-right: 0rem;
}

.g-padding-r-5--xs {
  padding-right: 0.3125rem;
}

.g-padding-r-10--xs {
  padding-right: 0.625rem;
}

.g-padding-r-15--xs {
  padding-right: 0.9375rem;
}

.g-padding-r-20--xs {
  padding-right: 1.25rem;
}

.g-padding-r-25--xs {
  padding-right: 1.5625rem;
}

.g-padding-r-30--xs {
  padding-right: 1.875rem;
}

.g-padding-r-35--xs {
  padding-right: 2.1875rem;
}

.g-padding-r-40--xs {
  padding-right: 2.5rem;
}

.g-padding-r-45--xs {
  padding-right: 2.8125rem;
}

.g-padding-r-50--xs {
  padding-right: 3.125rem;
}

.g-padding-r-55--xs {
  padding-right: 3.4375rem;
}

.g-padding-r-60--xs {
  padding-right: 3.75rem;
}

.g-padding-r-65--xs {
  padding-right: 4.0625rem;
}

.g-padding-r-70--xs {
  padding-right: 4.375rem;
}

.g-padding-r-75--xs {
  padding-right: 4.6875rem;
}

.g-padding-r-80--xs {
  padding-right: 5rem;
}

.g-padding-r-85--xs {
  padding-right: 5.3125rem;
}

.g-padding-r-90--xs {
  padding-right: 5.625rem;
}

.g-padding-r-95--xs {
  padding-right: 5.9375rem;
}

.g-padding-r-100--xs {
  padding-right: 6.25rem;
}

.g-padding-r-105--xs {
  padding-right: 6.5625rem;
}

.g-padding-r-110--xs {
  padding-right: 6.875rem;
}

.g-padding-r-115--xs {
  padding-right: 7.1875rem;
}

.g-padding-r-120--xs {
  padding-right: 7.5rem;
}

.g-padding-r-125--xs {
  padding-right: 7.8125rem;
}

.g-padding-r-130--xs {
  padding-right: 8.125rem;
}

.g-padding-r-135--xs {
  padding-right: 8.4375rem;
}

.g-padding-r-140--xs {
  padding-right: 8.75rem;
}

.g-padding-r-145--xs {
  padding-right: 9.0625rem;
}

.g-padding-r-150--xs {
  padding-right: 9.375rem;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-padding-r-0--sm {
    padding-right: 0rem;
  }
  .g-padding-r-5--sm {
    padding-right: 0.3125rem;
  }
  .g-padding-r-10--sm {
    padding-right: 0.625rem;
  }
  .g-padding-r-15--sm {
    padding-right: 0.9375rem;
  }
  .g-padding-r-20--sm {
    padding-right: 1.25rem;
  }
  .g-padding-r-25--sm {
    padding-right: 1.5625rem;
  }
  .g-padding-r-30--sm {
    padding-right: 1.875rem;
  }
  .g-padding-r-35--sm {
    padding-right: 2.1875rem;
  }
  .g-padding-r-40--sm {
    padding-right: 2.5rem;
  }
  .g-padding-r-45--sm {
    padding-right: 2.8125rem;
  }
  .g-padding-r-50--sm {
    padding-right: 3.125rem;
  }
  .g-padding-r-55--sm {
    padding-right: 3.4375rem;
  }
  .g-padding-r-60--sm {
    padding-right: 3.75rem;
  }
  .g-padding-r-65--sm {
    padding-right: 4.0625rem;
  }
  .g-padding-r-70--sm {
    padding-right: 4.375rem;
  }
  .g-padding-r-75--sm {
    padding-right: 4.6875rem;
  }
  .g-padding-r-80--sm {
    padding-right: 5rem;
  }
  .g-padding-r-85--sm {
    padding-right: 5.3125rem;
  }
  .g-padding-r-90--sm {
    padding-right: 5.625rem;
  }
  .g-padding-r-95--sm {
    padding-right: 5.9375rem;
  }
  .g-padding-r-100--sm {
    padding-right: 6.25rem;
  }
  .g-padding-r-105--sm {
    padding-right: 6.5625rem;
  }
  .g-padding-r-110--sm {
    padding-right: 6.875rem;
  }
  .g-padding-r-115--sm {
    padding-right: 7.1875rem;
  }
  .g-padding-r-120--sm {
    padding-right: 7.5rem;
  }
  .g-padding-r-125--sm {
    padding-right: 7.8125rem;
  }
  .g-padding-r-130--sm {
    padding-right: 8.125rem;
  }
  .g-padding-r-135--sm {
    padding-right: 8.4375rem;
  }
  .g-padding-r-140--sm {
    padding-right: 8.75rem;
  }
  .g-padding-r-145--sm {
    padding-right: 9.0625rem;
  }
  .g-padding-r-150--sm {
    padding-right: 9.375rem;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-padding-r-0--md {
    padding-right: 0rem;
  }
  .g-padding-r-5--md {
    padding-right: 0.3125rem;
  }
  .g-padding-r-10--md {
    padding-right: 0.625rem;
  }
  .g-padding-r-15--md {
    padding-right: 0.9375rem;
  }
  .g-padding-r-20--md {
    padding-right: 1.25rem;
  }
  .g-padding-r-25--md {
    padding-right: 1.5625rem;
  }
  .g-padding-r-30--md {
    padding-right: 1.875rem;
  }
  .g-padding-r-35--md {
    padding-right: 2.1875rem;
  }
  .g-padding-r-40--md {
    padding-right: 2.5rem;
  }
  .g-padding-r-45--md {
    padding-right: 2.8125rem;
  }
  .g-padding-r-50--md {
    padding-right: 3.125rem;
  }
  .g-padding-r-55--md {
    padding-right: 3.4375rem;
  }
  .g-padding-r-60--md {
    padding-right: 3.75rem;
  }
  .g-padding-r-65--md {
    padding-right: 4.0625rem;
  }
  .g-padding-r-70--md {
    padding-right: 4.375rem;
  }
  .g-padding-r-75--md {
    padding-right: 4.6875rem;
  }
  .g-padding-r-80--md {
    padding-right: 5rem;
  }
  .g-padding-r-85--md {
    padding-right: 5.3125rem;
  }
  .g-padding-r-90--md {
    padding-right: 5.625rem;
  }
  .g-padding-r-95--md {
    padding-right: 5.9375rem;
  }
  .g-padding-r-100--md {
    padding-right: 6.25rem;
  }
  .g-padding-r-105--md {
    padding-right: 6.5625rem;
  }
  .g-padding-r-110--md {
    padding-right: 6.875rem;
  }
  .g-padding-r-115--md {
    padding-right: 7.1875rem;
  }
  .g-padding-r-120--md {
    padding-right: 7.5rem;
  }
  .g-padding-r-125--md {
    padding-right: 7.8125rem;
  }
  .g-padding-r-130--md {
    padding-right: 8.125rem;
  }
  .g-padding-r-135--md {
    padding-right: 8.4375rem;
  }
  .g-padding-r-140--md {
    padding-right: 8.75rem;
  }
  .g-padding-r-145--md {
    padding-right: 9.0625rem;
  }
  .g-padding-r-150--md {
    padding-right: 9.375rem;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-padding-r-0--lg {
    padding-right: 0rem;
  }
  .g-padding-r-5--lg {
    padding-right: 0.3125rem;
  }
  .g-padding-r-10--lg {
    padding-right: 0.625rem;
  }
  .g-padding-r-15--lg {
    padding-right: 0.9375rem;
  }
  .g-padding-r-20--lg {
    padding-right: 1.25rem;
  }
  .g-padding-r-25--lg {
    padding-right: 1.5625rem;
  }
  .g-padding-r-30--lg {
    padding-right: 1.875rem;
  }
  .g-padding-r-35--lg {
    padding-right: 2.1875rem;
  }
  .g-padding-r-40--lg {
    padding-right: 2.5rem;
  }
  .g-padding-r-45--lg {
    padding-right: 2.8125rem;
  }
  .g-padding-r-50--lg {
    padding-right: 3.125rem;
  }
  .g-padding-r-55--lg {
    padding-right: 3.4375rem;
  }
  .g-padding-r-60--lg {
    padding-right: 3.75rem;
  }
  .g-padding-r-65--lg {
    padding-right: 4.0625rem;
  }
  .g-padding-r-70--lg {
    padding-right: 4.375rem;
  }
  .g-padding-r-75--lg {
    padding-right: 4.6875rem;
  }
  .g-padding-r-80--lg {
    padding-right: 5rem;
  }
  .g-padding-r-85--lg {
    padding-right: 5.3125rem;
  }
  .g-padding-r-90--lg {
    padding-right: 5.625rem;
  }
  .g-padding-r-95--lg {
    padding-right: 5.9375rem;
  }
  .g-padding-r-100--lg {
    padding-right: 6.25rem;
  }
  .g-padding-r-105--lg {
    padding-right: 6.5625rem;
  }
  .g-padding-r-110--lg {
    padding-right: 6.875rem;
  }
  .g-padding-r-115--lg {
    padding-right: 7.1875rem;
  }
  .g-padding-r-120--lg {
    padding-right: 7.5rem;
  }
  .g-padding-r-125--lg {
    padding-right: 7.8125rem;
  }
  .g-padding-r-130--lg {
    padding-right: 8.125rem;
  }
  .g-padding-r-135--lg {
    padding-right: 8.4375rem;
  }
  .g-padding-r-140--lg {
    padding-right: 8.75rem;
  }
  .g-padding-r-145--lg {
    padding-right: 9.0625rem;
  }
  .g-padding-r-150--lg {
    padding-right: 9.375rem;
  }
}

/*----------------------------------
  Padding X (left & right)
------------------------------------*/
.g-padding-x-0--xs {
  padding-left: 0rem;
  padding-right: 0rem;
}

.g-padding-x-5--xs {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

.g-padding-x-10--xs {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.g-padding-x-15--xs {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.g-padding-x-20--xs {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.g-padding-x-25--xs {
  padding-left: 1.5625rem;
  padding-right: 1.5625rem;
}

.g-padding-x-30--xs {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.g-padding-x-35--xs {
  padding-left: 2.1875rem;
  padding-right: 2.1875rem;
}

.g-padding-x-40--xs {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.g-padding-x-45--xs {
  padding-left: 2.8125rem;
  padding-right: 2.8125rem;
}

.g-padding-x-50--xs {
  padding-left: 3.125rem;
  padding-right: 3.125rem;
}

.g-padding-x-55--xs {
  padding-left: 3.4375rem;
  padding-right: 3.4375rem;
}

.g-padding-x-60--xs {
  padding-left: 3.75rem;
  padding-right: 3.75rem;
}

.g-padding-x-65--xs {
  padding-left: 4.0625rem;
  padding-right: 4.0625rem;
}

.g-padding-x-70--xs {
  padding-left: 4.375rem;
  padding-right: 4.375rem;
}

.g-padding-x-75--xs {
  padding-left: 4.6875rem;
  padding-right: 4.6875rem;
}

.g-padding-x-80--xs {
  padding-left: 5rem;
  padding-right: 5rem;
}

.g-padding-x-85--xs {
  padding-left: 5.3125rem;
  padding-right: 5.3125rem;
}

.g-padding-x-90--xs {
  padding-left: 5.625rem;
  padding-right: 5.625rem;
}

.g-padding-x-95--xs {
  padding-left: 5.9375rem;
  padding-right: 5.9375rem;
}

.g-padding-x-100--xs {
  padding-left: 6.25rem;
  padding-right: 6.25rem;
}

.g-padding-x-105--xs {
  padding-left: 6.5625rem;
  padding-right: 6.5625rem;
}

.g-padding-x-110--xs {
  padding-left: 6.875rem;
  padding-right: 6.875rem;
}

.g-padding-x-115--xs {
  padding-left: 7.1875rem;
  padding-right: 7.1875rem;
}

.g-padding-x-120--xs {
  padding-left: 7.5rem;
  padding-right: 7.5rem;
}

.g-padding-x-125--xs {
  padding-left: 7.8125rem;
  padding-right: 7.8125rem;
}

.g-padding-x-130--xs {
  padding-left: 8.125rem;
  padding-right: 8.125rem;
}

.g-padding-x-135--xs {
  padding-left: 8.4375rem;
  padding-right: 8.4375rem;
}

.g-padding-x-140--xs {
  padding-left: 8.75rem;
  padding-right: 8.75rem;
}

.g-padding-x-145--xs {
  padding-left: 9.0625rem;
  padding-right: 9.0625rem;
}

.g-padding-x-150--xs {
  padding-left: 9.375rem;
  padding-right: 9.375rem;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-padding-x-0--sm {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .g-padding-x-5--sm {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }
  .g-padding-x-10--sm {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .g-padding-x-15--sm {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
  .g-padding-x-20--sm {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .g-padding-x-25--sm {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
  .g-padding-x-30--sm {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .g-padding-x-35--sm {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }
  .g-padding-x-40--sm {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .g-padding-x-45--sm {
    padding-left: 2.8125rem;
    padding-right: 2.8125rem;
  }
  .g-padding-x-50--sm {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
  .g-padding-x-55--sm {
    padding-left: 3.4375rem;
    padding-right: 3.4375rem;
  }
  .g-padding-x-60--sm {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  .g-padding-x-65--sm {
    padding-left: 4.0625rem;
    padding-right: 4.0625rem;
  }
  .g-padding-x-70--sm {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }
  .g-padding-x-75--sm {
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
  }
  .g-padding-x-80--sm {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .g-padding-x-85--sm {
    padding-left: 5.3125rem;
    padding-right: 5.3125rem;
  }
  .g-padding-x-90--sm {
    padding-left: 5.625rem;
    padding-right: 5.625rem;
  }
  .g-padding-x-95--sm {
    padding-left: 5.9375rem;
    padding-right: 5.9375rem;
  }
  .g-padding-x-100--sm {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
  .g-padding-x-105--sm {
    padding-left: 6.5625rem;
    padding-right: 6.5625rem;
  }
  .g-padding-x-110--sm {
    padding-left: 6.875rem;
    padding-right: 6.875rem;
  }
  .g-padding-x-115--sm {
    padding-left: 7.1875rem;
    padding-right: 7.1875rem;
  }
  .g-padding-x-120--sm {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .g-padding-x-125--sm {
    padding-left: 7.8125rem;
    padding-right: 7.8125rem;
  }
  .g-padding-x-130--sm {
    padding-left: 8.125rem;
    padding-right: 8.125rem;
  }
  .g-padding-x-135--sm {
    padding-left: 8.4375rem;
    padding-right: 8.4375rem;
  }
  .g-padding-x-140--sm {
    padding-left: 8.75rem;
    padding-right: 8.75rem;
  }
  .g-padding-x-145--sm {
    padding-left: 9.0625rem;
    padding-right: 9.0625rem;
  }
  .g-padding-x-150--sm {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-padding-x-0--md {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .g-padding-x-5--md {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }
  .g-padding-x-10--md {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .g-padding-x-15--md {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
  .g-padding-x-20--md {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .g-padding-x-25--md {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
  .g-padding-x-30--md {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .g-padding-x-35--md {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }
  .g-padding-x-40--md {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .g-padding-x-45--md {
    padding-left: 2.8125rem;
    padding-right: 2.8125rem;
  }
  .g-padding-x-50--md {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
  .g-padding-x-55--md {
    padding-left: 3.4375rem;
    padding-right: 3.4375rem;
  }
  .g-padding-x-60--md {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  .g-padding-x-65--md {
    padding-left: 4.0625rem;
    padding-right: 4.0625rem;
  }
  .g-padding-x-70--md {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }
  .g-padding-x-75--md {
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
  }
  .g-padding-x-80--md {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .g-padding-x-85--md {
    padding-left: 5.3125rem;
    padding-right: 5.3125rem;
  }
  .g-padding-x-90--md {
    padding-left: 5.625rem;
    padding-right: 5.625rem;
  }
  .g-padding-x-95--md {
    padding-left: 5.9375rem;
    padding-right: 5.9375rem;
  }
  .g-padding-x-100--md {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
  .g-padding-x-105--md {
    padding-left: 6.5625rem;
    padding-right: 6.5625rem;
  }
  .g-padding-x-110--md {
    padding-left: 6.875rem;
    padding-right: 6.875rem;
  }
  .g-padding-x-115--md {
    padding-left: 7.1875rem;
    padding-right: 7.1875rem;
  }
  .g-padding-x-120--md {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .g-padding-x-125--md {
    padding-left: 7.8125rem;
    padding-right: 7.8125rem;
  }
  .g-padding-x-130--md {
    padding-left: 8.125rem;
    padding-right: 8.125rem;
  }
  .g-padding-x-135--md {
    padding-left: 8.4375rem;
    padding-right: 8.4375rem;
  }
  .g-padding-x-140--md {
    padding-left: 8.75rem;
    padding-right: 8.75rem;
  }
  .g-padding-x-145--md {
    padding-left: 9.0625rem;
    padding-right: 9.0625rem;
  }
  .g-padding-x-150--md {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-padding-x-0--lg {
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .g-padding-x-5--lg {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
  }
  .g-padding-x-10--lg {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .g-padding-x-15--lg {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
  .g-padding-x-20--lg {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .g-padding-x-25--lg {
    padding-left: 1.5625rem;
    padding-right: 1.5625rem;
  }
  .g-padding-x-30--lg {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  .g-padding-x-35--lg {
    padding-left: 2.1875rem;
    padding-right: 2.1875rem;
  }
  .g-padding-x-40--lg {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .g-padding-x-45--lg {
    padding-left: 2.8125rem;
    padding-right: 2.8125rem;
  }
  .g-padding-x-50--lg {
    padding-left: 3.125rem;
    padding-right: 3.125rem;
  }
  .g-padding-x-55--lg {
    padding-left: 3.4375rem;
    padding-right: 3.4375rem;
  }
  .g-padding-x-60--lg {
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  .g-padding-x-65--lg {
    padding-left: 4.0625rem;
    padding-right: 4.0625rem;
  }
  .g-padding-x-70--lg {
    padding-left: 4.375rem;
    padding-right: 4.375rem;
  }
  .g-padding-x-75--lg {
    padding-left: 4.6875rem;
    padding-right: 4.6875rem;
  }
  .g-padding-x-80--lg {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .g-padding-x-85--lg {
    padding-left: 5.3125rem;
    padding-right: 5.3125rem;
  }
  .g-padding-x-90--lg {
    padding-left: 5.625rem;
    padding-right: 5.625rem;
  }
  .g-padding-x-95--lg {
    padding-left: 5.9375rem;
    padding-right: 5.9375rem;
  }
  .g-padding-x-100--lg {
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
  .g-padding-x-105--lg {
    padding-left: 6.5625rem;
    padding-right: 6.5625rem;
  }
  .g-padding-x-110--lg {
    padding-left: 6.875rem;
    padding-right: 6.875rem;
  }
  .g-padding-x-115--lg {
    padding-left: 7.1875rem;
    padding-right: 7.1875rem;
  }
  .g-padding-x-120--lg {
    padding-left: 7.5rem;
    padding-right: 7.5rem;
  }
  .g-padding-x-125--lg {
    padding-left: 7.8125rem;
    padding-right: 7.8125rem;
  }
  .g-padding-x-130--lg {
    padding-left: 8.125rem;
    padding-right: 8.125rem;
  }
  .g-padding-x-135--lg {
    padding-left: 8.4375rem;
    padding-right: 8.4375rem;
  }
  .g-padding-x-140--lg {
    padding-left: 8.75rem;
    padding-right: 8.75rem;
  }
  .g-padding-x-145--lg {
    padding-left: 9.0625rem;
    padding-right: 9.0625rem;
  }
  .g-padding-x-150--lg {
    padding-left: 9.375rem;
    padding-right: 9.375rem;
  }
}

/*----------------------------------
  Padding Y (top & bottom)
------------------------------------*/
.g-padding-y-0--xs {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.g-padding-y-5--xs {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

.g-padding-y-10--xs {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.g-padding-y-15--xs {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}

.g-padding-y-20--xs {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.g-padding-y-25--xs {
  padding-top: 1.5625rem;
  padding-bottom: 1.5625rem;
}

.g-padding-y-30--xs {
  padding-top: 1.875rem;
  padding-bottom: 1.875rem;
}

.g-padding-y-35--xs {
  padding-top: 2.1875rem;
  padding-bottom: 2.1875rem;
}

.g-padding-y-40--xs {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.g-padding-y-45--xs {
  padding-top: 2.8125rem;
  padding-bottom: 2.8125rem;
}

.g-padding-y-50--xs {
  padding-top: 3.125rem;
  padding-bottom: 3.125rem;
}

.g-padding-y-55--xs {
  padding-top: 3.4375rem;
  padding-bottom: 3.4375rem;
}

.g-padding-y-60--xs {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem;
}

.g-padding-y-65--xs {
  padding-top: 4.0625rem;
  padding-bottom: 4.0625rem;
}

.g-padding-y-70--xs {
  padding-top: 4.375rem;
  padding-bottom: 4.375rem;
}

.g-padding-y-75--xs {
  padding-top: 4.6875rem;
  padding-bottom: 4.6875rem;
}

.g-padding-y-80--xs {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.g-padding-y-85--xs {
  padding-top: 5.3125rem;
  padding-bottom: 5.3125rem;
}

.g-padding-y-90--xs {
  padding-top: 5.625rem;
  padding-bottom: 5.625rem;
}

.g-padding-y-95--xs {
  padding-top: 5.9375rem;
  padding-bottom: 5.9375rem;
}

.g-padding-y-100--xs {
  padding-top: 6.25rem;
  padding-bottom: 6.25rem;
}

.g-padding-y-105--xs {
  padding-top: 6.5625rem;
  padding-bottom: 6.5625rem;
}

.g-padding-y-110--xs {
  padding-top: 6.875rem;
  padding-bottom: 6.875rem;
}

.g-padding-y-115--xs {
  padding-top: 7.1875rem;
  padding-bottom: 7.1875rem;
}

.g-padding-y-120--xs {
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.g-padding-y-125--xs {
  padding-top: 7.8125rem;
  padding-bottom: 7.8125rem;
}

.g-padding-y-130--xs {
  padding-top: 8.125rem;
  padding-bottom: 8.125rem;
}

.g-padding-y-135--xs {
  padding-top: 8.4375rem;
  padding-bottom: 8.4375rem;
}

.g-padding-y-140--xs {
  padding-top: 8.75rem;
  padding-bottom: 8.75rem;
}

.g-padding-y-145--xs {
  padding-top: 9.0625rem;
  padding-bottom: 9.0625rem;
}

.g-padding-y-150--xs {
  padding-top: 9.375rem;
  padding-bottom: 9.375rem;
}

/* Small screen / phone */
@media (min-width: 34em) {
  .g-padding-y-0--sm {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .g-padding-y-5--sm {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .g-padding-y-10--sm {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .g-padding-y-15--sm {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .g-padding-y-20--sm {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .g-padding-y-25--sm {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }
  .g-padding-y-30--sm {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .g-padding-y-35--sm {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
  .g-padding-y-40--sm {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .g-padding-y-45--sm {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }
  .g-padding-y-50--sm {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .g-padding-y-55--sm {
    padding-top: 3.4375rem;
    padding-bottom: 3.4375rem;
  }
  .g-padding-y-60--sm {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .g-padding-y-65--sm {
    padding-top: 4.0625rem;
    padding-bottom: 4.0625rem;
  }
  .g-padding-y-70--sm {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
  .g-padding-y-75--sm {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
  .g-padding-y-80--sm {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .g-padding-y-85--sm {
    padding-top: 5.3125rem;
    padding-bottom: 5.3125rem;
  }
  .g-padding-y-90--sm {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  .g-padding-y-95--sm {
    padding-top: 5.9375rem;
    padding-bottom: 5.9375rem;
  }
  .g-padding-y-100--sm {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .g-padding-y-105--sm {
    padding-top: 6.5625rem;
    padding-bottom: 6.5625rem;
  }
  .g-padding-y-110--sm {
    padding-top: 6.875rem;
    padding-bottom: 6.875rem;
  }
  .g-padding-y-115--sm {
    padding-top: 7.1875rem;
    padding-bottom: 7.1875rem;
  }
  .g-padding-y-120--sm {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .g-padding-y-125--sm {
    padding-top: 7.8125rem;
    padding-bottom: 7.8125rem;
  }
  .g-padding-y-130--sm {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .g-padding-y-135--sm {
    padding-top: 8.4375rem;
    padding-bottom: 8.4375rem;
  }
  .g-padding-y-140--sm {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
  .g-padding-y-145--sm {
    padding-top: 9.0625rem;
    padding-bottom: 9.0625rem;
  }
  .g-padding-y-150--sm {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
}

/* Medium screen / tablet */
@media (min-width: 48em) {
  .g-padding-y-0--md {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .g-padding-y-5--md {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .g-padding-y-10--md {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .g-padding-y-15--md {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .g-padding-y-20--md {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .g-padding-y-25--md {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }
  .g-padding-y-30--md {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .g-padding-y-35--md {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
  .g-padding-y-40--md {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .g-padding-y-45--md {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }
  .g-padding-y-50--md {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .g-padding-y-55--md {
    padding-top: 3.4375rem;
    padding-bottom: 3.4375rem;
  }
  .g-padding-y-60--md {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .g-padding-y-65--md {
    padding-top: 4.0625rem;
    padding-bottom: 4.0625rem;
  }
  .g-padding-y-70--md {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
  .g-padding-y-75--md {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
  .g-padding-y-80--md {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .g-padding-y-85--md {
    padding-top: 5.3125rem;
    padding-bottom: 5.3125rem;
  }
  .g-padding-y-90--md {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  .g-padding-y-95--md {
    padding-top: 5.9375rem;
    padding-bottom: 5.9375rem;
  }
  .g-padding-y-100--md {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .g-padding-y-105--md {
    padding-top: 6.5625rem;
    padding-bottom: 6.5625rem;
  }
  .g-padding-y-110--md {
    padding-top: 6.875rem;
    padding-bottom: 6.875rem;
  }
  .g-padding-y-115--md {
    padding-top: 7.1875rem;
    padding-bottom: 7.1875rem;
  }
  .g-padding-y-120--md {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .g-padding-y-125--md {
    padding-top: 7.8125rem;
    padding-bottom: 7.8125rem;
  }
  .g-padding-y-130--md {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .g-padding-y-135--md {
    padding-top: 8.4375rem;
    padding-bottom: 8.4375rem;
  }
  .g-padding-y-140--md {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
  .g-padding-y-145--md {
    padding-top: 9.0625rem;
    padding-bottom: 9.0625rem;
  }
  .g-padding-y-150--md {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
}

/* Extra large screen / wide desktop */
@media (min-width: 62em) {
  .g-padding-y-0--lg {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
  .g-padding-y-5--lg {
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
  }
  .g-padding-y-10--lg {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .g-padding-y-15--lg {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
  }
  .g-padding-y-20--lg {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .g-padding-y-25--lg {
    padding-top: 1.5625rem;
    padding-bottom: 1.5625rem;
  }
  .g-padding-y-30--lg {
    padding-top: 1.875rem;
    padding-bottom: 1.875rem;
  }
  .g-padding-y-35--lg {
    padding-top: 2.1875rem;
    padding-bottom: 2.1875rem;
  }
  .g-padding-y-40--lg {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .g-padding-y-45--lg {
    padding-top: 2.8125rem;
    padding-bottom: 2.8125rem;
  }
  .g-padding-y-50--lg {
    padding-top: 3.125rem;
    padding-bottom: 3.125rem;
  }
  .g-padding-y-55--lg {
    padding-top: 3.4375rem;
    padding-bottom: 3.4375rem;
  }
  .g-padding-y-60--lg {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem;
  }
  .g-padding-y-65--lg {
    padding-top: 4.0625rem;
    padding-bottom: 4.0625rem;
  }
  .g-padding-y-70--lg {
    padding-top: 4.375rem;
    padding-bottom: 4.375rem;
  }
  .g-padding-y-75--lg {
    padding-top: 4.6875rem;
    padding-bottom: 4.6875rem;
  }
  .g-padding-y-80--lg {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  .g-padding-y-85--lg {
    padding-top: 5.3125rem;
    padding-bottom: 5.3125rem;
  }
  .g-padding-y-90--lg {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
  .g-padding-y-95--lg {
    padding-top: 5.9375rem;
    padding-bottom: 5.9375rem;
  }
  .g-padding-y-100--lg {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
  .g-padding-y-105--lg {
    padding-top: 6.5625rem;
    padding-bottom: 6.5625rem;
  }
  .g-padding-y-110--lg {
    padding-top: 6.875rem;
    padding-bottom: 6.875rem;
  }
  .g-padding-y-115--lg {
    padding-top: 7.1875rem;
    padding-bottom: 7.1875rem;
  }
  .g-padding-y-120--lg {
    padding-top: 7.5rem;
    padding-bottom: 7.5rem;
  }
  .g-padding-y-125--lg {
    padding-top: 7.8125rem;
    padding-bottom: 7.8125rem;
  }
  .g-padding-y-130--lg {
    padding-top: 8.125rem;
    padding-bottom: 8.125rem;
  }
  .g-padding-y-135--lg {
    padding-top: 8.4375rem;
    padding-bottom: 8.4375rem;
  }
  .g-padding-y-140--lg {
    padding-top: 8.75rem;
    padding-bottom: 8.75rem;
  }
  .g-padding-y-145--lg {
    padding-top: 9.0625rem;
    padding-bottom: 9.0625rem;
  }
  .g-padding-y-150--lg {
    padding-top: 9.375rem;
    padding-bottom: 9.375rem;
  }
}

/*----------------------------------
  Ul li padding X (left & right)
------------------------------------*/
.g-ul-li-lr-0--xs > li {
  padding-left: 0rem;
  padding-right: 0rem;
}

.g-ul-li-lr-1--xs > li {
  padding-left: 0.0625rem;
  padding-right: 0.0625rem;
}

.g-ul-li-lr-2--xs > li {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.g-ul-li-lr-3--xs > li {
  padding-left: 0.1875rem;
  padding-right: 0.1875rem;
}

.g-ul-li-lr-4--xs > li {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.g-ul-li-lr-5--xs > li {
  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
}

.g-ul-li-lr-10--xs > li {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.g-ul-li-lr-15--xs > li {
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
}

.g-ul-li-lr-20--xs > li {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

/*----------------------------------
  Ul li padding Y (top & bottom)
------------------------------------*/
.g-ul-li-tb-0--xs > li {
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.g-ul-li-tb-1--xs > li {
  padding-top: 0.0625rem;
  padding-bottom: 0.0625rem;
}

.g-ul-li-tb-2--xs > li {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.g-ul-li-tb-3--xs > li {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
}

.g-ul-li-tb-4--xs > li {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.g-ul-li-tb-5--xs > li {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

.g-ul-li-tb-10--xs > li {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.g-ul-li-tb-15--xs > li {
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
}

.g-ul-li-tb-20--xs > li {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

/*----------------------------------
  Colors
------------------------------------*/
/* Text Colors */
.g-color--primary {
  color: #656f8c !important;
}

.g-color--blueviolet {
  color: #9877ea !important;
}

.g-color--red {
  color: #c54041 !important;
}

.g-color--gold {
  color: #b99769 !important;
}

.g-color--body {
  color: #fff !important;
}

.g-color--dark {
  color: #222324 !important;
}

.g-color--dark-light {
  color: #242526 !important;
}

.g-color--heading {
  color: #242424 !important;
}

.g-color--white {
  color: #fff !important;
}

.g-color--text {
  color: #656565 !important;
}

.g-color--gray-light {
  color: #cecece !important;
}

.g-color--gray-lighter {
  color: #aeaeae !important;
}

.g-color--white-opacity {
  color: rgba(255, 255, 255, 0.8) !important;
}

.g-color--white-opacity-light {
  color: rgba(255, 255, 255, 0.5) !important;
}

.g-color--white-opacity-lighter {
  color: rgba(255, 255, 255, 0.3) !important;
}

.g-color--white-opacity-lightest {
  color: rgba(255, 255, 255, 0.1) !important;
}

.g-color--sky-light {
  color: #f5f8f9 !important;
}

/* Hover Colors */
.g-color--text[href]:hover {
  color: #656f8c !important;
}

.g-color--white-opacity[href]:hover {
  color: #656f8c !important;
}

/* Background Colors */
.g-bg-color--primary {
  background-color: #656f8c !important;
}

.g-bg-color--blueviolet {
  background-color: #9877ea !important;
}

.g-bg-color--red {
  background-color: #c54041 !important;
}

.g-bg-color--gold {
  background-color: #b99769 !important;
}

.g-bg-color--body {
  background-color: #fff !important;
}

.g-bg-color--dark {
  background-color: #222324 !important;
}

.g-bg-color--dark-light {
  background-color: #242526 !important;
}

.g-bg-color--heading {
  background-color: #242424 !important;
}

.g-bg-color--white {
  background-color: #fff !important;
}

.g-bg-color--text {
  background-color: #656565 !important;
}

.g-bg-color--gray-light {
  background-color: #cecece !important;
}

.g-bg-color--gray-lighter {
  background-color: #aeaeae !important;
}

.g-bg-color--white-opacity {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

.g-bg-color--white-opacity-light {
  background-color: rgba(255, 255, 255, 0.5) !important;
}

.g-bg-color--white-opacity-lighter {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.g-bg-color--white-opacity-lightest {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.g-bg-color--sky-light {
  background-color: #9BBFAB !important;
}

.g-bg-color--primary-to-blueviolet-ltr {
  background-image: linear-gradient(145deg, rgba(19, 177, 205, 0.95) 0%, rgba(152, 119, 234, 0.95) 100%);
  background-repeat: repeat-x;
}

.g-bg-color--primary-ltr {
  background-image: linear-gradient(145deg, rgba(3, 83, 97, 0.6) 0%, #010629 100%);
  background-repeat: repeat-x;
}
